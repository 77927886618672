import React from "react";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ButtonBlue from "../buttonBlue";

const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "red",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#f15e5e",
          fontSize: 20,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
}));

const DeleteModal = (props) => {
  const classes = useStyles();
  const { open, onCloseDeleteModalHandle, text, label, onClickDelete } = props;

  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={onCloseDeleteModalHandle}
    >
      <DialogContent>
        <div style={{ width: "100%", height: 6, backgroundColor: "red" }}></div>
        <div className="content">
          <HighlightOffRoundedIcon className="delete-icon-label" />
          <Typography variant="h6" className={classes.title}>
            คุณแน่ใจใช่ไหม?
          </Typography>
          {label && <Typography className={classes.label}>{label}</Typography>}
          {text && (
            <Typography
              className="detail"
              variant="h6"
              style={{ color: "red" }}
              component="span"
            >
              {text}
            </Typography>
          )}

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={onCloseDeleteModalHandle}
            >
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              color="error"
              variant="contained"
              onClick={onClickDelete}
            >
              ยืนยัน
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default DeleteModal;
