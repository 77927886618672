import React, { useState } from "react";
import Card from "../Card";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/th";
import AllowModal from "./AllowModal";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteModal from "../../../../shared/deleteModal";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { set } from "date-fns";

const Item = ({ data, index, onChangeStatus, onClickLinkToDetail }) => {
  const [openAllowModal, setOpenAllowModal] = useState(false);
  const [openNotAllowModal, setOpenNotAllowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [indexData, setIndexData] = useState();
  const onConfirm = (technicianId) => {
    onChangeStatus(indexData, 2, technicianId);
  };

  const onCloseDeleteModalHandle = () => {
    setOpenDeleteModal(false);
  };

  const onCloseNotAllowModalHandle = () => {
    setOpenNotAllowModal(false);
  };
  const openModal = (index) => {
    setIndexData(index);
    setOpenAllowModal(true);
  };

  return (
    <Card>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          md={12}
          lg={10.5}
          alignItems="center"
          onClick={() => onClickLinkToDetail(data.idMaintenances)}
        >
          <Grid item xs={12} md={6} lg={3} spacing={2} alignItems="center">
            {parseInt(data.maintenanceType) === 1 && (
              <Typography fontSize={20}>
                <i className="fas fa-wrench"></i> ซ่อมแซมทั่วไป
              </Typography>
            )}
            {parseInt(data.maintenanceType) === 2 && (
              <Typography fontSize={20} color="#f8c243 !important">
                <i className="fas fa-bolt"></i> งานไฟฟ้า
              </Typography>
            )}
            {parseInt(data.maintenanceType) === 3 && (
              <Typography fontSize={20} color="cornflowerblue !important">
                <i className="fas fa-tint"></i> งานประปา
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={2.25} spacing={2} alignItems="center">
            <div style={{ display: "flex", alignItems: "center" }}>
              <CalendarMonthRoundedIcon
                style={{
                  marginRight: "5px",
                  fontSize: "20px",
                  color: "#00bb77",
                }}
              />
              <Typography className="title">วันที่แจ้ง</Typography>
            </div>
            <Typography>
              {dayjs(data.createdAt).locale("th").format("DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2.25} spacing={2} alignItems="center">
            <div style={{ display: "flex", alignItems: "center" }}>
              <PermContactCalendarRoundedIcon
                style={{
                  marginRight: "5px",
                  fontSize: "20px",
                  color: "#f8c243",
                }}
              />
              <Typography className="title">วันที่เริ่มงาน</Typography>
            </div>
            <Typography>
              {dayjs(data.startDate)
                .locale("th")
                .format("DD MMM YYYY (HH:mmน.)")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2} spacing={2} alignItems="center">
            <div style={{ display: "flex", alignItems: "center" }}>
              <LocationOnRoundedIcon
                style={{
                  marginRight: "5px",
                  fontSize: "20px",
                  color: "#d32f2f",
                }}
              />
              <Typography className="title">สถานที่</Typography>
            </div>
            <Typography>{data.location}</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2.5} spacing={2} alignItems="center">
            <Typography className="title">สถานะ</Typography>
            {parseInt(data.status) === 1 && (
              <Typography>
                <i className="far fa-clock"></i> กำลังรออนุมัติ...
              </Typography>
            )}
            {parseInt(data.status) === 2 && (
              <Typography>
                <i className="fad fa-spinner"></i> กำลังดำเนินการ
              </Typography>
            )}
            {parseInt(data.status) === 3 && (
              <Typography color="#43a047 !important">
                <i className="fas fa-check-circle"></i> ดำเนินการเสร็จสิ้น
              </Typography>
            )}
            {parseInt(data.status) === 4 && (
              <Typography color="#e53935 !important">
                <i className="fas fa-times-circle"></i> ไม่อนุมัติ
              </Typography>
            )}
            {parseInt(data.status) === 5 && (
              <Typography color="#e53935 !important">
                <i className="fas fa-trash"></i> ยกเลิก
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} lg={1.5} alignItems="center">
          {/* <Grid
          item
          xs={12}
          md={5}
          lg={1.5}
          spacing={2}
          alignItems="center"
          marginTop={1}
        >
          <Button
            variant="contained"
            component={Link}
            style={{ borderRadius: "15px" }}
            to={`/maintenances/detail/${data.idMaintenances}`}
          >
            รายละเอียด
          </Button>
        </Grid> */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                marginTop: "15px",
                justifyContent: "center",
              },
            })}
          >
            {parseInt(data.status) === 1 && (
              <>
                <IconButton
                  style={{
                    marginRight: 8,
                    width: 40,
                    height: 40,
                    backgroundColor: "#4BB543",
                    ["&:hover"]: {
                      backgroundColor: "#43A047",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    openModal(index);
                  }}
                >
                  <CheckRoundedIcon style={{ fontSize: 24, color: "white" }} />
                </IconButton>
                <IconButton
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "#e53935",
                    ["&:hover"]: {
                      backgroundColor: "#e03935",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenNotAllowModal(true);
                  }}
                >
                  <CloseRoundedIcon style={{ fontSize: 24, color: "white" }} />
                </IconButton>
              </>
            )}
            {/* {parseInt(data.status) === 2 && (
              <>
                <IconButton
                  className="button rejectButton"
                  variant="contained"
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </IconButton>
              </>
            )} */}
          </Grid>
        </Grid>
      </Grid>
      {openAllowModal && (
        <AllowModal
          open={openAllowModal}
          onClose={() => {
            setOpenAllowModal(false);
          }}
          onConfirm={onConfirm}
        />
      )}
      {openNotAllowModal && (
        <DeleteModal
          open={openNotAllowModal}
          onCloseDeleteModalHandle={onCloseNotAllowModalHandle}
          onClickDelete={() => {
            onChangeStatus(index, 4, null);
            onCloseNotAllowModalHandle();
          }}
          label={"ปฏิเสธคำขออนุมัติการซ่อมแซมนี้"}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          onCloseDeleteModalHandle={onCloseDeleteModalHandle}
          onClickDelete={() => {
            onChangeStatus(index, 5, null);
            onCloseDeleteModalHandle();
          }}
          label={"ยกเลิกคำขออนุมัติการซ่อมแซมนี้"}
        />
      )}
    </Card>
  );
};

export default Item;
