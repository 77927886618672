import React from "react";
import { styled, Paper } from "@mui/material";

const Root = styled(Paper)(({ theme }) => ({
  padding: "24px 48px",
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
  position: "relative",
  overflow: "hidden", // Ensure background doesn't overflow the card
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
  "& .background": {
    position: "absolute",
    top: 0,
    left: "30vw",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    clipPath: "polygon(0% 0%, 100% 0%, 100.1% 100.3%, 20% 100.3%)",
    transition: "left 0.3s ease-in-out, opacity 0.3s ease-in-out",
    opacity: 0.6,
  },
  "&:hover .background": {
    left: "20vw",
    opacity: 1,
  },
  "& .content": {
    position: "relative",
    zIndex: 1,
  },
  "&:hover": {
    boxShadow: "0 8px 16px 0 rgb(35 31 32 / 10%), 0 0 3px 0 rgb(35 31 32 / 5%)",
    boxSizing: "border-box",
    transform: "translateY(-2px)",
  },
}));

const Card = ({ children, style, backgroundImage }) => {
  return (
    <Root style={style}>
      {backgroundImage && (
        <div
          className="background"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
      )}
      <div className="content">{children}</div>
    </Root>
  );
};

export default Card;
