import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTheme } from "@mui/material/styles"; // Import useMediaQuery
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { Grid, Avatar, Stepper, StepLabel, Step } from "@mui/material";
import ButtonBlue from "../../../shared/buttonBlue";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: grey[100],
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  cursor: "pointer",
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[900],
  }),
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  [theme.breakpoints.down("md")]: {
    width: 80,
    height: 80,
  },
  ...(theme.palette.mode === "dark" && {
    backgroundColor: grey[900],
  }),
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // Check if it's a desktop screen
  const [steps, setSteps] = useState(
    props.Booking
      ? [props.Booking.fromPlaceName, props.Booking.toPlaceName]
      : []
  );
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (props.Booking) {
      setSteps([props.Booking.fromPlaceName, props.Booking.toPlaceName]);
      if (props.Booking.targetStatus !== "GettingPlace") {
        setActiveStep(1);
      }
    }
  }, [props.Booking]);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: isDesktop
              ? `calc(45% - ${drawerBleeding}px)`
              : `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      {isDesktop && (
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDrawer(!open)}
          sx={{
            position: "fixed",
            bottom: drawerBleeding,
            right: 16,
            zIndex: "10",
            borderRadius: "20px 20px 0 0",
          }}
        >
          More Infomation
        </Button>
      )}
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller onClick={toggleDrawer(!open)} />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {props.Booking ? "ผู้โดยสารกำลังเรียกคุณ!" : "ไม่มีผู้โดยสาร"}
          </Typography>
        </StyledBox>
        <StyledBox sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>
          {/* InfoWindow-like content goes here */}
          {props.Booking && props.Booking.user ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Grid container spacing={2} sx={{ maxWidth: 600 }}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <AvatarStyled
                    src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                    alt={"image"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                  lg={9}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h4">
                    {`${props.Booking.user.firstname_TH} ${props.Booking.user.lastname_TH}`}
                  </Typography>
                  <Typography variant="h6">
                    Tel: {props.Booking.user.telephoneMobile}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {isDesktop ? (
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (index === 0) {
                          labelProps.optional = (
                            <Typography variant="caption">จุดรับ</Typography>
                          );
                        } else {
                          labelProps.optional = (
                            <Typography variant="caption">จุดส่ง</Typography>
                          );
                        }

                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  ) : (
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step}>
                          <StepLabel
                            optional={
                              index === 0 ? (
                                <Typography variant="caption">
                                  จุดรับ
                                </Typography>
                              ) : (
                                <Typography variant="caption">
                                  จุดส่ง
                                </Typography>
                              )
                            }
                          >
                            {step}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {props.Booking.targetStatus === "GettingPlace" ? (
                    <ButtonBlue
                      variant="contained"
                      style={{ width: "100%" }}
                      color="warning"
                      onClick={() => props.OnClickButtonUpdateStatus()}
                    >
                      รับผู้โดยสาร
                    </ButtonBlue>
                  ) : (
                    <ButtonBlue
                      variant="contained"
                      style={{ width: "100%" }}
                      onClick={() => props.OnClickButtonSetFinish()}
                    >
                      ส่งผู้โดยสาร
                    </ButtonBlue>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              ไม่พบข้อมูล
            </Typography>
          )}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
