import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { SnackbarProvider, useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import FormInformant from "./formInformat";
import FormFlight from "./formFlight/index";
import NavButtonForm from "../../../../shared/NavButton/NavButtonForm";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import { getAllUsers } from "../../../../../../actions/user";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useSelector, useDispatch } from "react-redux";

import inAreaCarBookingService from "../../../../../../services/inAreaCarBooking.service";
import {
  getInAreaCarBookingByStartDate,
  getInAreaCarBookingByStartDateAndEndDate,
} from "../../../../../../actions/inAreaCarBooking";
const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingInline: "5vw",
    // [theme.breakpoints.down("sm")]: {
    //   paddingInline: "0",
    //   marginLeft: "0",
    //   width: "100%",
    // },
  },
  Grid: {
    width: "80%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
}));

const DivHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // marginBottom: "20px",
  marginTop: "20px",
});

const BackButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  color: "black",
  fontSize: "20px",
  // left: "30px",
}));

const EditDetailInAreaCarBooking = (props) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState(props.location.state.booking);
  // const AllInput = watch();
  const [openDetailBooking, setOpenDetailBooking] = useState(false);
  const [stateInputRef, setStateInputRef] = useState({
    fromPlaceReturn: "",
    toPlaceReturn: "",
    listAllPassengers: [{ order: "" }],
    statusUsingAllPassenger: false,
  });
  const { result: AllUsers } = useSelector((state) => state.users);
  const { user: User } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!AllUsers) {
      dispatch(getAllUsers());
    }
    const startTime = SplitTimeReverse(booking.startTime);
    const endTime = SplitTimeReverse(booking.endTime);
    const Approved = {
      idEmployees: booking.idApprovedUser,
      firstname_TH: booking.nameApproved.split(" ")[0],
      lastname_TH: booking.nameApproved.split(" ")[1],
      departmentName: booking.departmentApproved,
      companyName: booking.companyApproved,
    };
    setValue("idinAreaCarBooking", booking.idinAreaCarBooking);
    setValue("idUser", booking.idUser);
    setValue("name", booking.name);
    setValue("telephoneMobile", booking.telephoneMobile);
    setValue("email", booking.email);
    setValue("section", booking.section);
    setValue("department", booking.department);
    setValue("flight", booking.flight);
    setValue("fromPlace", booking.fromPlace);
    setValue("toPlace", booking.toPlace);
    setValue("departureDate", booking.departureDate);
    setValue("startTime", startTime);
    setValue("endTime", endTime);
    setValue("numberOfPassenger", parseInt(booking.numberOfPassenger));
    setValue("idTypeCar", booking.idTypeCar);
    setValue("costBooking", "");
    setValue("purpose", booking.purpose);
    setValue("note", booking.note);
    setValue("company", booking.company);
    setValue("costCenter", booking.costCenter);
    setValue("costElement", booking.costElement);
    setValue("Approved", Approved);
    setValue(
      "nameApproved",
      `${Approved.firstname_TH} ${Approved.lastname_TH}`
    );
    setValue("departmentApproved", Approved.departmentName);
    setValue("companyApproved", Approved.companyName);
    setValue("idApproved", Approved.idEmployees);
  }, []);

  const showRegister = (date) => {
    setOpenDetailBooking(true);
    console.log(date);
  };

  const onCloseDialog = () => {
    setOpenDetailBooking(false);
  };

  const SplitTimeReverse = (dateString) => {
    const date = new Date(dateString);
    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minutes}`;
  };

  const onClickSubmit = async (data) => {
    let myData = data;
    myData.idUser = User.idUser;
    console.log(myData);
    inAreaCarBookingService.postUpdateInAreaCarBooking(myData).then((data) => {
      if (data.type === "success") {
        enqueueSnackbar("Update Booking Success", { variant: "success" });
        fetchData();
        props.history.push("/admin/car_management/in_area");
      } else {
        enqueueSnackbar("Update Booking Failed", { variant: "error" });
      }
    });
  };

  const fetchData = () => {
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getInAreaCarBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getInAreaCarBookingByStartDate(date));
    }
  };

  return (
    <div className={classes.DivCoverReturn}>
      <DivHead>
        <BackButton onClick={() => props.history.goBack()}>
          <ArrowBackIosNewIcon /> Back
        </BackButton>

        <Typography className={classes.Title}>
          แก้ไขการจองรถในพื้นที่
        </Typography>
        <div></div>
      </DivHead>
      <form style={{ width: "80%" }} onSubmit={handleSubmit(showRegister)}>
        <FormInformant
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <FormFlight
          register={register}
          control={control}
          watch={watch}
          fromPlace={booking.fromPlace}
          toPlace={booking.toPlace}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
        <NavButtonForm type="submit" numberOfPage={1} />
      </form>
      {openDetailBooking && (
        <DialogShowDetailBooking
          open={openDetailBooking}
          handleClose={onCloseDialog}
          getValues={getValues}
          watch={watch}
          onClickSubmit={handleSubmit(onClickSubmit)}
        />
      )}
    </div>
  );
};

export default EditDetailInAreaCarBooking;
