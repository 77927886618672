import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TruckLocation from "../../../assets/truck.png";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import siteService from "../../../../../services/site.service";
import Direction from "./Direction";

const MarkerDriver = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);
  const [siteFrom, setSiteFrom] = useState(null);
  const [siteTo, setSiteTo] = useState(null);
  const [directions, setDirections] = useState(null);

  const truckIcon = {
    url: TruckLocation,
    scaledSize: new window.google.maps.Size(25, 20), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(12.5, 20), // Anchor point
  };

  useEffect(() => {
    setSiteFrom(props.siteFrom);
    setSiteTo(props.siteTo);

    const updateLocationDriver = setInterval(() => {
      siteService
        .getLocationDriverById(currentUser.idUser)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([{ Lat: 0, Long: 0 }]);
        });
    }, 5000);
    return () => clearInterval(updateLocationDriver);
  }, []);
  useEffect(() => {
    if (props.inforInCar != null) {
      if (props.inforInCar.targetStatus === "GettingPlace") {
        if (siteFrom != null) {
          console.log("directionsService");
          const directionsService = new window.google.maps.DirectionsService();

          directionsService.route(
            {
              origin: new window.google.maps.LatLng(
                props.isLocationDriver[0].Lat,
                props.isLocationDriver[0].Long
              ),
              destination: new window.google.maps.LatLng(
                siteFrom.Lat,
                siteFrom.Long
              ),
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        }
      } else {
        if (siteTo != null) {
          console.log("directionsService");
          const directionsService = new window.google.maps.DirectionsService();

          directionsService.route(
            {
              origin: new window.google.maps.LatLng(
                props.isLocationDriver[0].Lat,
                props.isLocationDriver[0].Long
              ),
              destination: new window.google.maps.LatLng(
                siteTo.Lat,
                siteTo.Long
              ),
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        }
      }
    } else {
      setDirections(null);
    }
  }, [siteFrom, siteTo, props.inforInCar]);
  console.log("Directions", directions);
  console.log(props.inforInCar);

  return (
    <>
      <Marker
        position={{
          lat: parseFloat(isLocationDriver[0].Lat),
          lng: parseFloat(isLocationDriver[0].Long),
        }}
        icon={truckIcon}
      />
      {directions && <Direction directions={directions} />}
    </>
  );
};

export default MarkerDriver;
