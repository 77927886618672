import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";

const useStyles = makeStyles((theme) => ({}));

const GridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

const CardStyled = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "120px",
  boxShadow: theme.shadows[3],
  display: "flex",
  padding: "0 10px",
  borderRadius: "10px",
  cursor: "pointer",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 10px 0 rgba(73, 73, 73, 0.8)",
  },
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 0",
  },
}));
const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "90px",
  height: "90px",
  [theme.breakpoints.down("lg")]: {
    width: "120px",
    height: "120px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
}));

const TextSmall = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
}));

const HighlightText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
}));

const HighlightTextBig = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "bold",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
  },
}));

const GridPlace = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
  },
}));

const GridContentWithIcon = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CardBooking = (props) => {
  const classes = useStyles();

  return (
    <GridItem item xs={12} sm={6} md={4} lg={12}>
      <CardStyled
        onClick={() =>
          props.onClickCardHandler(
            props.typeOfBooking,
            props.booking.idCrossAreaCarBooking
          )
        }
      >
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1.2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AvatarStyled
              src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
              alt="1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <HighlightText>{props.booking.name}</HighlightText>
            <Text>{props.booking.email}</Text>
            <Text>{`Tel. ${props.booking.telephoneMobile}`}</Text>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2.3}>
            <GridPlace item xs={12}>
              <CalendarMonthRoundedIcon style={{ marginRight: "5px" }} />
              <Text>
                {dayjs(props.booking.departureDate).format("D MMM YYYY")}
              </Text>
            </GridPlace>
            <GridPlace item xs={12}>
              <QueryBuilderRoundedIcon style={{ marginRight: "5px" }} />
              <TextSmall>
                {`${dayjs(props.booking.startTime).format("HH.mm")} น.- ${dayjs(
                  props.booking.endTime
                ).format("HH.mm")} น.`}
              </TextSmall>
            </GridPlace>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <GridPlace item xs={12}>
              <LocationOnIcon style={{ marginRight: "5px", color: "green" }} />
              <Text>{props.booking.fromPlace}</Text>
            </GridPlace>
            <GridPlace item xs={12}>
              <LocationOnIcon style={{ marginRight: "5px", color: "red" }} />
              <Text>{props.booking.toPlace}</Text>
            </GridPlace>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1.5}
            sx={{ textAlign: "center" }}
          >
            <HighlightText>
              {props.booking.flight === "oneWay" ? "เที่ยวเดียว" : "ไป-กลับ"}
            </HighlightText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={1}
            sx={{ textAlign: "center" }}
          >
            <HighlightTextBig>
              {props.booking.numberOfPassenger}
            </HighlightTextBig>
            <Text>คน</Text>
          </Grid>
        </Grid>
      </CardStyled>
    </GridItem>
  );
};

export default CardBooking;
