import React, { useState, useEffect } from "react";
import {
  styled,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import cateringService from "../../../../../../services/catering.service";
import ButtonBlue from "../../../../shared/buttonBlue";

const FileInputWrapper = styled(Box)({
  border: "2px dashed #ccc",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100%",
  height: "200px",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  backgroundColor: "#f9f9f9",
  "&:hover": {
    backgroundColor: "#e9e9e9",
  },
});

const HiddenInput = styled("input")({
  display: "none",
});

const CenterContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

const ImagePreview = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
});

const FileInput = ({ onChange, fileName, index, fileUrl }) => {
  const [fileUrlName, setFileUrlName] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (fileUrl) {
      const fileNameFromUrl = fileUrl.split("/").pop().split("?")[0];
      setFileUrlName(fileNameFromUrl);
      setImageSrc(fileUrl);
    }
  }, [fileUrl]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    onChange(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => setImageSrc(e.target.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleChipClick = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FileInputWrapper
        onClick={() => document.getElementById(`file-input-${index}`).click()}
      >
        {fileName || fileUrlName ? (
          <>
            {fileName ? (
              <Chip
                label={fileName}
                onClick={handleChipClick}
                style={{ justifyContent: "flex-start" }}
              />
            ) : (
              <Chip
                label={fileUrlName}
                onClick={handleChipClick}
                style={{ justifyContent: "flex-start" }}
              />
            )}
          </>
        ) : (
          <CenterContent>
            <CloudUploadIcon style={{ fontSize: 48, color: "#ccc" }} />
            <Typography variant="body1" color="textSecondary">
              คลิก หรือ ลาก ไฟล์ที่ต้องการ
            </Typography>
          </CenterContent>
        )}
        <HiddenInput
          id={`file-input-${index}`}
          type="file"
          onChange={handleFileChange}
        />
      </FileInputWrapper>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <ImagePreview src={imageSrc} alt="Preview" />
        </DialogContent>
      </Dialog>
    </>
  );
};
const EditMenu = ({ onSuccessHandler, data, editRestaurant, sendfile }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState([]);
  const [deletedMenu, setDeletedMenu] = useState([]);

  useEffect(() => {
    if (editRestaurant) {
      let old_menu = [];
      for (let i = 0; i < editRestaurant.foods.length; i++) {
        old_menu.push({
          idCateringFood: editRestaurant.foods[i].idCateringFood,
          menuName: editRestaurant.foods[i].menuName,
          price: editRestaurant.foods[i].price,
          detail: editRestaurant.foods[i].detail,
          file: null,
          fileChanged: false,
          fileUrl: editRestaurant.foods[i].fileUrl,
          image: editRestaurant.foods[i].image
            ? JSON.parse(editRestaurant.foods[i].image)[0].path
            : null,
          old: true,
        });
      }
      setFields(old_menu);
    }
  }, [editRestaurant]);

  // Handle changes for each field
  const handleFieldChange = (index, name, value) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };

  // Handle file change
  const handleFileChange = (index, file) => {
    const updatedFields = [...fields];
    if (fields[index].old) {
      updatedFields[index].fileChanged = true;
    } else {
      updatedFields[index].fileAdded = true;
    }
    updatedFields[index].file = file;
    setFields(updatedFields);
  };

  // Handle delete menu item
  const handleDeleteField = (index) => {
    if (fields[index].old) {
      setDeletedMenu([...deletedMenu, fields[index]]);
    }
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  // Add new menu item
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        menuName: "",
        price: "",
        detail: "",
        file: null,
        old: false,
        fileAdded: false,
      },
    ]);
  };

  const onSubmit = () => {
    const formData = new FormData();
    let Restaurant = data;
    Restaurant.data.idCateringRestaurant = editRestaurant.idCateringRestaurant;

    const resultData = {
      Restaurant: Restaurant,
      Menu: fields,
      deletedMenu: deletedMenu,
    };

    formData.append("Result", JSON.stringify(resultData));
    formData.append("resFile", sendfile);

    fields.forEach((field) => {
      formData.append("attachment", field.file);
    });

    console.log(resultData);

    // Example submission logic

    cateringService.updateRestaurantMenu(formData).then((res) => {
      if (res.status === 200) {
        onSuccessHandler(resultData);
        enqueueSnackbar("Edit Menu Success", { variant: "success" });
      } else {
        enqueueSnackbar("Edit Menu Fail", { variant: "error" });
      }
    });
  };
  const onSkip = () => {
    const formData = new FormData();
    let Restaurant = data;
    Restaurant.data.idCateringRestaurant = editRestaurant.idCateringRestaurant;
    let old_menu = [];
    for (let i = 0; i < editRestaurant.foods.length; i++) {
      old_menu.push({
        idCateringFood: editRestaurant.foods[i].idCateringFood,
        menuName: editRestaurant.foods[i].menuName,
        price: editRestaurant.foods[i].price,
        detail: editRestaurant.foods[i].detail,
        file: null,
        fileChanged: false,
        fileUrl: editRestaurant.foods[i].fileUrl,
        image: editRestaurant.foods[i].image
          ? JSON.parse(editRestaurant.foods[i].image)[0].path
          : null,
        old: true,
      });
    }
    const resultData = {
      Restaurant: Restaurant,
      Menu: old_menu,
      deletedMenu: [],
    };

    formData.append("Result", JSON.stringify(resultData));
    formData.append("resFile", sendfile);

    // fields.forEach((field) => {
    //   formData.append("attachment", field.file);
    // });

    console.log(resultData);

    // Example submission logic

    cateringService.updateRestaurantMenu(formData).then((res) => {
      if (res.status === 200) {
        onSuccessHandler(resultData);
        enqueueSnackbar("Edit Menu Success", { variant: "success" });
      } else {
        enqueueSnackbar("Edit Menu Fail", { variant: "error" });
      }
    });
  };
  console.log(fields);

  return (
    <form>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontSize={32} marginBottom={2} marginTop={1}>
            แก้ไขเมนูอาหาร
          </Typography>
          <ButtonBlue variant="text" size="large" onClick={onSkip}>
            ข้ามขั้นตอนนี้
          </ButtonBlue>
        </Grid>
        {fields.map((field, index) => (
          <Grid
            item
            container
            spacing={3}
            key={index}
            sx={(theme) => ({
              display: "flex",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "0px",
              padding: "0 25px 25px 0",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {},
              [theme.breakpoints.down("md")]: {},
            })}
          >
            {/* {console.log(field)} */}
            <Grid
              item
              container
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>เมนูที่ {index + 1}</Typography>
              <IconButton
                onClick={() => handleDeleteField(index)}
                variant="outlined"
                color="error"
              >
                <CloseRoundedIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`menuName_${index}`}
                control={control}
                defaultValue={field.menuName}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="ชื่อเมนู"
                    onChange={(e) => {
                      onChange(e);
                      handleFieldChange(index, "menuName", e.target.value);
                    }}
                    value={field.menuName}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    helperText={
                      errors[`menuName_${index}`]
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={Boolean(errors[`menuName_${index}`])}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`price_${index}`}
                control={control}
                defaultValue={field.price}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="ราคา"
                    onChange={(e) => {
                      onChange(e);
                      handleFieldChange(index, "price", e.target.value);
                    }}
                    value={field.price}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    helperText={
                      errors[`price_${index}`]
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={Boolean(errors[`price_${index}`])}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`detail_${index}`}
                control={control}
                defaultValue={field.detail}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="รายละเอียดเพิ่มเติม"
                    onChange={(e) => {
                      onChange(e);
                      handleFieldChange(index, "detail", e.target.value);
                    }}
                    value={field.detail}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    rows={4}
                    helperText={
                      errors[`detail_${index}`]
                        ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                        : false
                    }
                    error={Boolean(errors[`detail_${index}`])}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item container xs={12}>
              <Typography>แนบไฟล์รูปภาพ (สูงสุด 1 ไฟล์)</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <FileInput
                index={index}
                fileName={field.file ? field.file.name : ""}
                onChange={(file) => handleFileChange(index, file)}
                fileUrl={field.fileUrl}
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} container justifyContent="center">
          <ButtonBlue
            style={{
              borderRadius: "50%",
              backgroundColor: "#21b6ae",
              padding: "17px 5px 17px 5px",
            }}
            onClick={handleAddField}
            variant="contained"
          >
            <AddIcon />
          </ButtonBlue>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            style={{
              maxWidth: "150px",
              maxHeight: "60px",
              color: "white",
              marginTop: "5px",
              borderRadius: "15px",
            }}
            variant="contained"
          >
            ยืนยัน
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditMenu;
