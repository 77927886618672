import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Avatar from "@mui/material/Avatar";
import TruckLocation from "../../../user/sampleshuttle/NewSample/CardDetailSample/showMap/Icon/truck.png";
import EmergencyLocation from "../../../assets/emergency_location.png";
import FmdBadRoundedIcon from "@mui/icons-material/FmdBadRounded";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { SvgIcon } from "@mui/material";
import SiteService from "../../../../../services/site.service";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";

require("dayjs/locale/th");
dayjs.locale("th");

const blink = keyframes`
  0% { box-shadow: 0 0 0px 0 rgba(255, 0, 0, 0.7); }
  50% { box-shadow: 0 0 15px 10px rgba(255, 0, 0, 0.7); }
  100% { box-shadow: 0 0 0px 0 rgba(255, 0, 0, 0.7); }
  50% { box-shadow: 0 0 15px 10px rgba(255, 0, 0, 0.7); }
`;

const BlinkingAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  animation: `${blink} 1.5s infinite`,
}));

const DriverMarker = (props) => {
  const [isLocationDriver, setLocationDriver] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState(null); // State for selected driver

  const redFmdBadIcon = {
    url: EmergencyLocation,
    scaledSize: new window.google.maps.Size(40, 40), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(20, 40), // Anchor point
  };
  const truckIcon = {
    url: TruckLocation,
    scaledSize: new window.google.maps.Size(25, 20), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(12.5, 20), // Anchor point
  };

  useEffect(() => {
    SiteService.getAllLocationDriver().then((res) => {
      setLocationDriver(res.data);
    });
    // dispatch(getEmergency());

    const interval = setInterval(() => {
      setCount(count + 1);
    }, 10000);

    //Clearing the interval
    return () => clearInterval(interval);

    //Clearing the interval
  }, [count]);

  const handleMapClick = () => {
    setSelectedDriver(null);
  };

  console.log(isLocationDriver);
  return (
    <>
      {isLocationDriver != undefined &&
        isLocationDriver.map((data) => {
          const isEmergency = data.emergencyStatus;
          const icon = isEmergency ? redFmdBadIcon : truckIcon;

          return (
            <Marker
              position={{
                lat: parseFloat(data.LatDriver),
                lng: parseFloat(data.LngDriver),
              }}
              key={data.idLocationDriver}
              icon={icon}
              onClick={() => setSelectedDriver(data)} // Set selected driver on marker click
            />
          );
        })}
      {/* Display InfoWindow if a driver is selected */}
      {selectedDriver && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedDriver.LatDriver),
            lng: parseFloat(selectedDriver.LngDriver),
          }}
          onCloseClick={() => setSelectedDriver(null)} // Close the InfoWindow
          options={{ pixelOffset: new window.google.maps.Size(0, -20) }} // Move InfoWindow above the marker
        >
          <div>
            <div
              style={{
                width: "100%",
                height: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              {selectedDriver.emergencyStatus ? (
                <BlinkingAvatar
                  src={`${process.env.REACT_APP_URL}image/profile/${selectedDriver.image}`}
                  alt={selectedDriver.image}
                />
              ) : (
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={`${process.env.REACT_APP_URL}image/profile/${selectedDriver.image}`}
                  alt={selectedDriver.image}
                />
              )}
            </div>
            <h2>{selectedDriver.FullName}</h2>
            <p>Tel: {selectedDriver.Telephone}</p>
            {selectedDriver.emergencyStatus && (
              <p style={{ color: "red" }}>
                Emergency: {selectedDriver.emergency.causeEmergency}
              </p>
            )}
            <p>
              Location:{" "}
              {`${selectedDriver.LatDriver}, ${selectedDriver.LngDriver}`}
            </p>
          </div>
        </InfoWindow>
      )}

      {/* Add a map click listener to close the InfoWindow */}
      <div onClick={handleMapClick}></div>
    </>
  );
};

export default DriverMarker;
