import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Paper,
  Button,
  Typography,
  Grid,
  Avatar,
  useMediaQuery,
  Divider,
} from "@mui/material";

import { getCrossAreaCarBookingPassengerByIdBooking } from "../../../../../actions/crossAreaCarBookingPassenger";

import EmployeeService from "../../../../../services/employee.service.js";
require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const DividerPassenger = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: theme.palette.primary.light,
  opacity: "0.5",
}));

const TextPassenger = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontSize: "20px",
  color: theme.palette.primary.main,
  marginTop: "10px",
}));

const DetailDriverBooking = (props) => {
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            {booking.twoWay === 1 && (
              <Grid item xs={12} sm={12}>
                <TextPassenger>ขาไป</TextPassenger>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Text>{`ต้นทาง : ${booking.namePlaceFrom}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`ปลายทาง : ${booking.namePlaceTo}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่ : ${dayjs(booking.startDate).format(
                  "DD MMMM YYYY"
                )} - ${dayjs(booking.endDate).format("DD MMMM YYYY")}`}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`เวลา : ${booking.startTime} - ${booking.endTime}`}</Text>
            </Grid>
            {booking.twoWay === 1 && (
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <TextPassenger>ขากลับ</TextPassenger>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text>{`ต้นทาง : ${booking.namePlaceFromReturn}`}</Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text>{`ปลายทาง : ${booking.namePlaceToReturn}`}</Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text>
                    {`วันที่ : ${dayjs(booking.startDateReturn).format(
                      "DD MMMM YYYY"
                    )} - ${dayjs(booking.endDateReturn).format(
                      "DD MMMM YYYY"
                    )}`}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text>
                    {`เวลา : ${booking.startTimeReturn} - ${booking.endTimeReturn}`}
                  </Text>
                </Grid>
              </Fragment>
            )}
          </GridContainer>
          <DividerStyled />

          <Fragment>
            <GridContainer container spacing={1}>
              <Grid item xs={12} md={6}>
                <Text>
                  รายละเอียดการเดินทาง :{" "}
                  {booking.detailJourney &&
                  booking.detailJourney !== null &&
                  booking.detailJourney !== ""
                    ? booking.detailJourney
                    : " - "}
                </Text>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Text>
                  รายละเอียดการเพิ่มเติม :{" "}
                  {booking.note && booking.note !== null && booking.note !== ""
                    ? booking.note
                    : " - "}
                </Text>
              </Grid>
            </GridContainer>
          </Fragment>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailDriverBooking;
