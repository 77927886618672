import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import {
  Grid,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { getCrossAreaCarPoolBookingPassengerByIdBooking } from "../../../../../actions/crossAreaCarPoolBookingPassenger";

require("dayjs/locale/th");
dayjs.locale("th");

const ButtonWatchTime = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: "20px",
  top: "13px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: "20px",
  padding: "5px 30px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const IconButtonWatchTime = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "20px",
  top: "13px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: "20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Content = styled("div")({
  "& .DividerFlight": {
    marginTop: "0px",
    opacity: "0.2",
  },
  "& .TextFlight": {
    margin: "10px 0",
  },
});

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const DividerPassenger = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: theme.palette.primary.light,
  opacity: "0.5",
}));

const TextPassenger = styled(Typography)(({ theme }) => ({
  width: "100%",
  fontSize: "20px",
  color: theme.palette.primary.main,
  marginBottom: "10px",
}));

const DetailCrossAreaCarPool = (props) => {
  const dispatch = useDispatch();
  const { user: AuthUser } = useSelector((state) => state.auth);
  const { result: Passengers } = useSelector((state) => state.passengers);
  const [booking, setBooking] = useState(null);
  const MobileResponsive = useMediaQuery(`(max-width:600px)`);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
      dispatch(
        getCrossAreaCarPoolBookingPassengerByIdBooking(
          props.booking.idCrossAreaCarPoolBooking
        )
      );
    }
  }, []);

  return (
    <Fragment>
      {booking && (
        <Fragment>
          {Passengers &&
            Passengers.find(
              (passenger) => passenger.idUser === AuthUser.id
            ) && (
              <>
                {!MobileResponsive ? (
                  <ButtonWatchTime
                    disableRipple
                    startIcon={<AccessTimeIcon style={{ fontSize: "30px" }} />}
                    onClick={props.onClickWatchTime}
                  >
                    ดูเวลา
                  </ButtonWatchTime>
                ) : (
                  <IconButtonWatchTime onClick={props.onClickWatchTime}>
                    <AccessTimeIcon />
                  </IconButtonWatchTime>
                )}
              </>
            )}
          <Content>
            <GridContainer container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Text>{`ต้นทาง : ${booking.fromPlace}`}</Text>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Text>{`ปลายทาง : ${booking.toPlace}`}</Text>
              </Grid>
              {booking.flight === "twoWay" && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Text>{`ต้นทางขากลับ : ${booking.fromPlaceReturn}`}</Text>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Text>{`ปลายทางขากลับ : ${booking.toPlaceReturn}`}</Text>
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={12} sm={6}>
                <Text>
                  {`วันที่ : ${dayjs(booking.departureDate).format(
                    "DD MMMM YYYY"
                  )}`}
                </Text>
              </Grid>
              {booking.flight === "twoWay" && (
                <Grid item xs={12} sm={6}>
                  <Text>
                    {`วันที่ขากลับ : ${dayjs(booking.returnDate).format(
                      "DD MMMM YYYY"
                    )}`}
                  </Text>
                </Grid>
              )}
            </GridContainer>
            <DividerStyled />
            {Passengers &&
              Passengers.map((passenger, index) => (
                <Fragment key={passenger.id}>
                  <TextPassenger>ผู้โดยสารคนที่ {index + 1}</TextPassenger>
                  <GridContainer container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Text>ชื่อ : {passenger.name}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>เบอร์โทรศัพท์ : {passenger.telephoneMobile}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>email : {passenger.email}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>บริษัท : {passenger.company}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>
                        Cost Center :{" "}
                        {passenger.costCenter && passenger.costCenter !== ""
                          ? passenger.costCenter
                          : "-"}
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>
                        Cost Element :{" "}
                        {passenger.costElement && passenger.costElement !== ""
                          ? passenger.costElement
                          : "-"}
                      </Text>
                    </Grid>
                  </GridContainer>
                  <TextPassenger className="TextFlight">ขาไป</TextPassenger>
                  <DividerPassenger className="DividerFlight" />
                  <GridContainer container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Text>จุดรับ : {passenger.fromPlace}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>จุดส่ง : {passenger.toPlace}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>รอบรถ : {passenger.roundTime}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>เวลาที่ต้องถึงจุดหมาย : {passenger.endTime}</Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>
                        วัตถุประสงค์ :{" "}
                        {passenger.purpose ? passenger.purpose : "-"}
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text>
                        Note : {passenger.note ? passenger.note : "-"}
                      </Text>
                    </Grid>
                  </GridContainer>
                  {booking.flight === "twoWay" && (
                    <Fragment>
                      <TextPassenger className="TextFlight">
                        ขากลับ
                      </TextPassenger>
                      <DividerPassenger className="DividerFlight" />
                      <GridContainer container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Text>จุดรับ : {passenger.fromPlaceReturn}</Text>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Text>จุดส่ง : {passenger.toPlaceReturn}</Text>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Text>รอบรถ : {passenger.roundTimeReturn}</Text>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Text>
                            เวลาที่ต้องถึงจุดหมาย : {passenger.endTimeReturn}
                          </Text>
                        </Grid>
                        <Grid item xs={12}>
                          <Text>
                            Note :{" "}
                            {passenger.noteReturn ? passenger.noteReturn : "-"}
                          </Text>
                        </Grid>
                      </GridContainer>
                    </Fragment>
                  )}
                  {Passengers.length !== index + 1 && <DividerPassenger />}
                </Fragment>
              ))}
          </Content>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailCrossAreaCarPool;
