import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  styled,
  Grid,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Paper,
  FormControl,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import DateAdapter from "@mui/lab/AdapterDayjs";
import Item from "./components/Item";
import Container from "@mui/material/Container";
import UnicornLoading from "../../assets/loading.gif";
import maintenanceService from "../../../../services/maintenance.service";
import { getUserProfile } from "../../../../actions/user";
import CreateExcel from "../../shared/createExcel";
import dayjs from "dayjs";
import { display, width } from "@mui/system";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const HeaderOfDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
}));

const HeaderOfTitle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "75%",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const HeaderOfButton = styled("div")(({ theme }) => ({
  display: "flex",
  width: "25%",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)({
  fontSize: "15px",
});

const head_Maintenances = [
  "Maintenance ID",
  "Name",
  "Telephone",
  "Email",
  "Department",
  "Company",
  "Cost Center",
  "Cost Element",
  "Maintenance Type",
  "Description",
  "Location",
  "Location Detail",
  "Date",
  "Time",
  "Process",
  "Status",
  "Created At",
  "Updated At",
];

const MaintenaceForManager = (props) => {
  const dispatch = useDispatch();
  const { register, getValues, control } = useForm();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [company, setCompany] = useState(userProfile?.companyName);
  const [maintenancesFormat, setMaintenancesFormat] = useState([]);
  const [requestedMaintenances, setRequestedMaintenances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userProfile) {
      dispatch(getUserProfile(currentUser?.idUser));
    }
    maintenanceService.getAllMaintenanceRequest().then((res) => {
      const temp = res.data.data.toReversed();
      setRequestedMaintenances(temp);
      setMaintenancesFormatData(res.data.data);
      setLoading(false);
      setError(res.data.error);
    });
  }, []);

  const onClickSearch = () => {
    setLoading(true);
    setRequestedMaintenances([]);
    maintenanceService
      .getAllMaintenanceRequest({
        maintenanceType: getValues("maintenanceType") || null,
        status: getValues("status") || null,
        date: getValues("date") || null,
      })
      .then((res) => {
        const temp = res.data.data.toReversed();
        setRequestedMaintenances(temp);
        setMaintenancesFormatData(res.data.data);
        setLoading(false);
        setError(res.data.error);
      });
  };

  const onChangeStatus = (index, status, technicianId) => {
    const newRequestedMaintenances = [...requestedMaintenances];
    const changeStatus = {
      maintenanceId: requestedMaintenances[index].idMaintenances,
      status: status,
      technicianId: technicianId == null ? [] : technicianId,
    };
    console.log(changeStatus);
    maintenanceService.putChangeStatus(changeStatus).then((res) => {
      newRequestedMaintenances[index].status = +res.data.data.status;
      setRequestedMaintenances(newRequestedMaintenances);
      setMaintenancesFormatData(newRequestedMaintenances);
    });
  };

  const onClickLinkToDetail = (index) => {
    props.history.push(`/maintenances/detail/${index}`);
  };

  const setMaintenancesFormatData = (data) => {
    const format = data.map((item) => {
      const status = parseInt(item.status);
      return {
        idMaintenances: item.idMaintenances,
        requestorName: item.requestorName,
        phoneNumber: item.phoneNumber,
        email: item.email,
        department: item.agency,
        company: item.company,
        costCenter: item.costCenter,
        costElement: item.costElement,
        maintenanceType:
          item.maintenanceType === 1
            ? "ซ่อมแซมทั่วไป"
            : item.maintenanceType === 2
            ? "งานไฟฟ้า"
            : "งานประปา",
        description: item.description,
        location: item.location,
        locationDetail: item.locationDetail,
        startDate: dayjs(item.startDate).format("DD MMM YYYY"),
        time: dayjs(item.startDate).format("HH:mm"),
        progress: `${item.progress} %`,
        status:
          status === 1
            ? "กำลังรออนุมัติ"
            : status === 2
            ? "กำลังดำเนินการ"
            : status === 3
            ? "ดำเนินการเสร็จสิ้น"
            : status === 4
            ? "ไม่อนุมัติ"
            : "ยกเลิก",
        createdAt: dayjs(item.createdAt).format("DD MMM YYYY (HH:mm)"),
        updatedAt: dayjs(item.updatedAt).format("DD MMM YYYY (HH:mm)"),
      };
    });
    setMaintenancesFormat(format);
  };

  return (
    <Container maxWidth="xl">
      <div className="page">
        <Root>
          <HeaderOfDiv>
            <HeaderOfTitle>
              <Typography fontSize={34}>รายการแจ้งซ่อมทั้งหมด</Typography>
            </HeaderOfTitle>
            <HeaderOfButton>
              <CreateExcel
                name="Maintenances_Requested"
                headerValues={head_Maintenances}
                data={maintenancesFormat}
              />
            </HeaderOfButton>
          </HeaderOfDiv>
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                border: "1px solid #EEEEEE",
                backgroundColor: "#FBFCFC",
                padding: "0 30px 25px 0",
                marginLeft: "5px",
                marginTop: "10px",
                borderRadius: "15px",
              })}
            >
              <Grid item xs={12}>
                <Stack>
                  <Text>ชื่อบริษัท</Text>

                  {userProfile && (
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        placeholder="เลือกบริษัท"
                        id="company"
                        defaultValue={company}
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                      >
                        <MenuItem
                          key={userProfile.idCompany}
                          value={userProfile.companyName}
                        >
                          {userProfile.companyName}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <Text>ประเภทงานซ่อม</Text>
                  <TextField
                    inputProps={{ ...register("maintenanceType") }}
                    // label="ประเภทงานซ่อม"
                    select
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                    defaultValue={0}
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    <MenuItem value={1}>ซ่อมแซมทั่วไป</MenuItem>
                    <MenuItem value={2}>งานไฟฟ้า</MenuItem>
                    <MenuItem value={3}>งานประปา</MenuItem>
                  </TextField>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <Text>วันที่แจ้ง</Text>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          // label="วันที่แจ้ง"
                          inputFormat="DD MMMM YYYY"
                          // clearable
                          componentsProps={{
                            actionBar: {
                              actions: ["clear", "cancel", "accept"],
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="วัน เดือน ปี"
                              InputProps={{
                                style: {
                                  borderRadius: "15px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <Text>สถานะ</Text>
                  <TextField
                    inputProps={{ ...register("status") }}
                    // label="สถานะ"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                    select
                    defaultValue={0}
                  >
                    <MenuItem value={0}>ทั้งหมด</MenuItem>
                    <MenuItem value={1}>กำลังรออนุมัติ</MenuItem>
                    <MenuItem value={2}>กำลังดำเนินการ</MenuItem>
                    <MenuItem value={3}>ดำเนินการเสร็จสิ้น</MenuItem>
                    <MenuItem value={4}>ไม่อนุมัติ</MenuItem>
                  </TextField>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onClickSearch}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "40px",
                    // margin: "10px",
                    borderRadius: "15px",
                  }}
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>

            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    overflow: "hidden",
                    border: "1px solid #EEEEEE",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "20px",
                    padding: "25px 30px 25px 30px",
                    borderRadius: "15px",
                    textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                  }}
                >
                  <img
                    src={UnicornLoading}
                    alt="Loading..."
                    style={{ width: "300px" }}
                  />
                  {/* ปรับขนาดของภาพตามที่ต้องการ */}
                </Paper>
              </div>
            )}
            {!loading && requestedMaintenances.length === 0 && (
              <Grid item xs={12}>
                <Typography fontSize={20} textAlign="center">
                  ไม่พบประวัติการแจ้งซ่อม
                </Typography>
              </Grid>
            )}
            {requestedMaintenances.map((data, index) => (
              <Grid
                key={`maintenance_${index}`}
                item
                xs={12}
                md={6}
                lg={12}
                style={{ paddingRight: "0 !important", marginTop: "5px" }}
              >
                <Item
                  data={data}
                  onChangeStatus={onChangeStatus}
                  index={index}
                  onClickLinkToDetail={onClickLinkToDetail}
                />
              </Grid>
            ))}
          </Grid>
        </Root>
      </div>
    </Container>
  );
};

export default MaintenaceForManager;
