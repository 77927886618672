import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Chip,
  Autocomplete,
  Avatar,
  styled,
  Typography,
} from "@mui/material";
import AvatarUpload from "../Avatar";
import maintenanceService from "../../../../../../services/maintenance.service";
import { SnackbarProvider, useSnackbar } from "notistack";

const MaintenanceTypes = [
  {
    title: "ช่างซ่อมแซมทั่วไป",
  },
  {
    title: "ช่างไฟฟ้า",
  },
  {
    title: "ช่างประปา",
  },
];

const Form = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idMaintenanceTechnicians: props.technician?.idMaintenanceTechnicians,
      types: props.types,
      image: null,
      path: props.technician?.image,
      fullName: props.technician?.fullName,
      phoneNumber: props.technician?.phoneNumber,
      email: props.technician?.email,
      description: props.technician?.description,
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("idMaintenanceTechnicians", data.idMaintenanceTechnicians);
    formData.append("image", data.image);
    formData.append("path", data.path);
    formData.append("fullName", data.fullName);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("email", data.email);
    let types = data.types.map((x) => x.title);
    // console.log(types);
    formData.append("types", types);
    formData.append("description", data.description);
    console.log(formData);
    maintenanceService
      .editTechnician(formData)
      .then((res) => {
        if (res.status === 200) {
          props.onSuccessHandler();
          enqueueSnackbar("Update technician success", { variant: "success" });
        } else {
          enqueueSnackbar("Update technician fail", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Update technician fail", { variant: "error" });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Controller
            name="image"
            control={control}
            // rules={{
            //   validate: (data) => {
            //     if (!data) return false;
            //   },
            // }}
            render={() => (
              <div>
                <AvatarUpload
                  setValue={setValue}
                  getValues={getValues}
                  fileUrl={props.technician?.fileUrl}
                />
                {/* {errors.image && !getValues("image") && (
                  <Typography
                    fontSize={12}
                    style={{ marginTop: 14, color: "#d32f2f" }}
                  >
                    กรุณาใส่รูปของคุณ
                  </Typography>
                )} */}
              </div>
            )}
          />
        </Grid>
        <Grid
          container
          item
          spacing={3}
          xs={12}
          sx={(theme) => ({
            display: "flex",
            boxShadow:
              "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
            border: "1px solid #EEEEEE",
            backgroundColor: "#FBFCFC",
            marginTop: "10px",
            marginBottom: "20px",
            marginLeft: "0px",
            padding: "0 25px 25px 0",

            borderRadius: "15px",
            [theme.breakpoints.down("sm")]: {},
            [theme.breakpoints.down("md")]: {},
          })}
        >
          <Grid item xs={12} md={4}>
            <TextField
              label="ชื่อ-นามสกุล"
              fullWidth
              inputProps={{ ...register("fullName", { required: true }) }}
              helperText={errors.fullName ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
              error={errors.fullName ? true : false}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="เบอร์โทร"
              fullWidth
              inputProps={{ ...register("phoneNumber", { required: true }) }}
              helperText={
                errors.phoneNumber ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
              }
              error={errors.phoneNumber ? true : false}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Email"
              fullWidth
              inputProps={{ ...register("email", { required: true }) }}
              helperText={errors.email ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false}
              error={errors.email ? true : false}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="types"
              control={control}
              rules={{
                validate: (data) => {
                  if (data.length === 0) return false;
                },
              }}
              render={({ field }) => (
                <>
                  <Autocomplete
                    {...field}
                    multiple
                    options={MaintenanceTypes}
                    getOptionLabel={(option) => option.title}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ประเภทงานซ่อม"
                        placeholder="ประเภทงานซ่อม"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled",
                        }}
                        helperText={
                          errors.types ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                        }
                        error={errors.types ? true : false}
                      />
                    )}
                    onChange={(e, data) => field.onChange(data)}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="รายละเอียดเพิ่มเติม"
              fullWidth
              multiline
              rows={5}
              inputProps={{ ...register("description", { required: true }) }}
              helperText={
                errors.description ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
              }
              error={errors.description ? true : false}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="warning"
            onClick={() => props.onSuccessHandler()}
            sx={{ marginRight: "10px" }}
          >
            ย้อนกลับ
          </Button>
          <Button variant="contained" type="submit">
            ลงทะเบียน
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
