import React, { Component, useState } from "react";

// import { GoogleApiWrapper, Map, InfoWindow, Marker } from "google-maps-react";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../../utils/googleMapAssets";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { TextField, Grid } from "@mui/material";
// import { GoogleMap, Marker } from "react-google-maps";
const placesLibrary = ["places"];

const GoogleApi = (props) => {
  const [searchResult, setSearchResult] = useState("Result: none");
  const [isLocation, setLocation] = useState([
    { Lat: 13.8127228, Lng: 100.539289 },
  ]);
  const [map, setMap] = React.useState(null);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      setLocation([
        {
          Lat: place.geometry.location.lat(),
          Lng: place.geometry.location.lng(),
        },
      ]);

      setData(place.name);
    } else {
      alert("Please enter text");
    }
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: parseFloat(isLocation[0].Lat),
    lng: parseFloat(isLocation[0].Lng),
  };

  const setData = (data) => {
    props.handleGetDataFromGoogleMap(data, props.index);
  };

  return (
    <>
      {isLoaded && (
        // <div style={{ height: "100%", width: "100%" }}>
        // <div id="searchColumn">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
              <TextField
                type="text"
                placeholder="Search"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "15px",
                    width: "100%",
                    marginTop: "5px",
                    marginBottom: "5px",
                  },
                }}
              />
            </Autocomplete>
          </Grid>

          <Grid item xs={12} style={{ height: "300px" }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={13}
            >
              <Marker
                position={{
                  lat: parseFloat(isLocation[0].Lat),
                  lng: parseFloat(isLocation[0].Lng),
                }}
              />
            </GoogleMap>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GoogleApi;
