import axios from "axios";
import { httpClient } from "./httpClient";

const API_URL = process.env.REACT_APP_API_URL + "auth/";

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      // console.log(response.data)

      if (response.data.type === "not_found") {
        alert(response.data.msg);
        window.location = "/login";
        return;
      } else if (response.data.type === "password_invalid") {
        alert(response.data.msg);
        window.location = "/login";
        return;
      }

      if (response.data.returnData.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data.returnData));
      }

      return response.data.returnData;
    });
};

const checkToken = (sessions) => {
  return axios
    .post(
      process.env.REACT_APP_API_URL + "auth/verify-token",
      { sessions },
      {
        headers: {
          "x-access-token": sessions,
        },
      }
    )
    .then((res) => {
      if (res.data.returnData.accessToken) {
        localStorage.setItem("user", JSON.stringify(res.data.returnData));
      }

      return res.data.returnData;
    })
    .catch((err) => console.error(err));
  // return axios
  //   .post(process.env.REACT_APP_API_URL + "auth/verify-token", {
  //     sessions
  //   })
  //   .then((response) => {
  //     // console.log(response.data)

  //     if(response.data.type === "not_found"){
  //       alert(response.data.msg)
  //       window.location = "/login"
  //       return
  //     }
  //     else if(response.data.type === "password_invalid"){
  //       alert(response.data.msg)
  //       window.location = "/login"
  //       return
  //     }

  //     if (response.data.returnData.accessToken) {
  //       localStorage.setItem("user", JSON.stringify(response.data.returnData));

  //     }

  //     return response.data.returnData;
  //   });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
  checkToken,
};
