import driverEmergencyService from "../services/driverEmergency.service";
import {
  EMERGENCY_FETCHING,
  EMERGENCY_FAILED,
  EMERGENCY_SUCCESS,
} from "./types";

export const getEmergency = () => async (dispatch) => {
  try {
    dispatch({
      type: EMERGENCY_FETCHING,
    });
    const res = await driverEmergencyService
      .getDriverEmergencyThatIsNotFinish()
      .then((res) => {
        if (res) {
          dispatch({
            type: EMERGENCY_SUCCESS,
            payload: res.data,
          });
        }
      });
  } catch (error) {
    dispatch({
      type: EMERGENCY_FAILED,
    });
  }
};
