import axios from "axios";
import { httpClient } from "./httpClient";
const API_URL =
  process.env.REACT_APP_API_URL + "delivery_sample_shuttleBystartDate";
const API_URL2 =
  process.env.REACT_APP_API_URL + "delivery_sample_shuttle_byIdUser";
const API_URL3 =
  process.env.REACT_APP_API_URL + "delivery_sample_shuttle_byIdBooking";
const API_URL4 =
  process.env.REACT_APP_API_URL + "delivery_sample_shuttle_byIdDriver";
const API_URL5 =
  process.env.REACT_APP_API_URL + "delivery_sample_shuttle_UpdateStatus";
const API_URL6 = process.env.REACT_APP_API_URL + "delivery_sample_shuttle_All";
const API_URL7 =
  process.env.REACT_APP_API_URL + "get_delivery_sample_shuttle_by_no";
const API_URL8 =
  process.env.REACT_APP_API_URL + "get_delivery_sample_shuttle_by_filter";
const API_URL9 = process.env.REACT_APP_API_URL + "edit_delivery_sample_shuttle";
const API_URL10 =
  process.env.REACT_APP_API_URL + "delete_delivery_sample_shuttle";
const API_URL11 =
  process.env.REACT_APP_API_URL + "get_urlfile_delivery_sample_shuttle";
const API_URL12 =
  process.env.REACT_APP_API_URL +
  "get_delivery_sample_shuttle_by_filter_by_id_driver";
const postDeliverySampleshuttleByStartDate = (newData) => {
  if (newData) {
    return httpClient
      .post(API_URL, newData, {
        headers: {
          "Content-Type": "multipart/form-data", //ตัวเพิ่มfileเข้าHeader
        },
      })
      .then((Response) => {
        if (Response.data.type === "success") {
          return Response.data;
        }
      });
  }
};

const getDeliverySampleshuttleByIdUser = (id) => {
  console.log(API_URL2);
  return httpClient.get(API_URL2 + `/${id}`);
};

const getDeliverySampleshuttleByIdBooking = (id) => {
  console.log(API_URL3);
  return httpClient.get(API_URL3 + `/${id}`);
};

const getDeliverySampleshuttleByIdDriver = (id) => {
  // axios.get(API_URL4 + `/${id}`).then((Response) => {
  //     console.log("Ressss", Response.data)})
  return httpClient.get(API_URL4 + `/${id}`);
};

const postUpdateDeliveryStatus = (newData) => {
  if (newData) {
    return httpClient.post(API_URL5, { newData }).then((Response) => {
      if (Response.data.type === "success") {
        return Response;
      }
    });
  }
};

const getDeliverySampleshuttleAll = (id) => {
  return httpClient.get(API_URL6 + `/${id}`);
};

const getDeliverySampleShuttleByNo = () => {
  return httpClient.get(API_URL7);
};
const getDeliverySampleShuttleByFilter = (dataFilter) => {
  if (dataFilter) {
    return httpClient.post(API_URL8, dataFilter).then((Response) => {
      if (Response.data.type === "success") {
        return Response;
      }
    });
  }
};

const postEditDeliverySampleshuttle = (newData, id) => {
  if (newData) {
    return httpClient
      .post(API_URL9 + `/${id}`, newData, {
        headers: {
          "Content-Type": "multipart/form-data", //ตัวเพิ่มfileเข้าHeader
        },
      })
      .then((Response) => {
        if (Response.data.type === "success") {
          return Response.data;
        }
      });
  }
};

const deleteDeliverySampleshuttle = (myData) => {
  return httpClient.post(API_URL10, { myData }).then((Response) => {
    if (Response.data.type === "success") {
      return Response;
    }
  });
};
const getDeliverySampleShuttleByFilterByIdDriver = (dataFilter) => {
  if (dataFilter) {
    return httpClient.post(API_URL12, dataFilter).then((Response) => {
      if (Response.data.type === "success") {
        return Response;
      }
    });
  }
};

// const getFileDeliverySampleshuttleById = (id) => {
//   return httpClient.get(API_URL11 + `/${id}`).then((Response) => {
//     if (Response.data.type === "success") {
//       return Response;
//     }
//   });
// };

export default {
  postDeliverySampleshuttleByStartDate,
  getDeliverySampleshuttleByIdUser,
  getDeliverySampleshuttleByIdBooking,
  getDeliverySampleshuttleByIdDriver,
  postUpdateDeliveryStatus,
  getDeliverySampleshuttleAll,
  getDeliverySampleShuttleByNo,
  getDeliverySampleShuttleByFilter,
  postEditDeliverySampleshuttle,
  deleteDeliverySampleshuttle,
  getDeliverySampleShuttleByFilterByIdDriver,
  // getFileDeliverySampleshuttleById,
};
