import React, { useEffect, useState } from "react";
import TruckLocation from "../../../user/sampleshuttle/NewSample/CardDetailSample/showMap/Icon/truck.png";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import siteService from "../../../../../services/site.service";
import Direction from "./Direction";

const MarkerDriver = (props) => {
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);
  const [siteFrom, setSiteFrom] = useState(null);
  const [siteTo, setSiteTo] = useState(null);
  const [directions, setDirections] = useState(null);

  const truckIcon = {
    url: TruckLocation,
    scaledSize: new window.google.maps.Size(25, 20), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(12.5, 20), // Anchor point
  };

  useEffect(() => {
    if (props.inforInCar != null) {
      if (props.siteFrom != null && props.siteTo != null) {
        setSiteFrom(props.siteFrom);
        setSiteTo(props.siteTo);
      }
      if (props.isBetweenSiteCar) {
        const updateLocationDriver = setInterval(() => {
          siteService
            .getLocationDriverById(props.inforInCar.idDriverRouteDay)
            .then((res) => {
              if (res.data) {
                setLocationDriver([
                  { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
                ]);
              }
            })
            .catch((error) => {
              setLocationDriver([{ Lat: 0, Long: 0 }]);
            });
        }, 1000);
        return () => clearInterval(updateLocationDriver);
      } else {
        const updateLocationDriver = setInterval(() => {
          siteService
            .getLocationDriverById(props.inforInCar.idDriver)
            .then((res) => {
              if (res.data) {
                setLocationDriver([
                  { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
                ]);
              }
            })
            .catch((error) => {
              setLocationDriver([{ Lat: 0, Long: 0 }]);
            });
        }, 1000);
        return () => clearInterval(updateLocationDriver);
      }
    }
  }, [props.inforInCar]);
  useEffect(() => {
    if (siteFrom != null) {
      console.log("directionsService");
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: new window.google.maps.LatLng(
            props.isLocationDriver[0].Lat,
            props.isLocationDriver[0].Long
          ),
          destination: new window.google.maps.LatLng(
            siteFrom.Lat,
            siteFrom.Long
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [siteFrom]);
  console.log("Directions", directions);
  console.log(props.inforInCar);
  useEffect(() => {
    if (props.inforInCar) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: new window.google.maps.LatLng(
            props.isLocationDriver[0].Lat,
            props.isLocationDriver[0].Long
          ), // ใช้พิกัดตำแหน่งเริ่มต้นที่คุณมี
          destination: props.inforInCar.fromPlace, // ใช้ชื่อสถานที่ปลายทาง
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [props.inforInCar]);

  return (
    <>
      <Marker
        position={{
          lat: parseFloat(isLocationDriver[0].Lat),
          lng: parseFloat(isLocationDriver[0].Long),
        }}
        icon={truckIcon}
      />
      {directions && <Direction directions={directions} />}
    </>
  );
};

export default MarkerDriver;
