import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// import Card from "../../shared/Card";

require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  Card: {
    marginBottom: "20px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    // boxShadow: theme.shadows[3],
    transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",

    borderRadius: "15px",
    cursor: "pointer",
    border: "1px solid #EEEEEE",
    "&:hover": {
      transform: "translateY(-8px)",
      boxShadow:
        "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
    },
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-between",
    },
    paddingBottom: "10px",
  },
  Avatar: {
    width: "100px",
    height: "100px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
  },
  Name: {
    color: "#185ADB",
    fontSize: "20px",
    marginBottom: "10px",
  },
  TextAndIcon: {
    display: "flex",
    marginBottom: "5px",
  },
  Icon: {
    marginRight: "10px",
    color: "#293A80",
  },
  IconButton: {
    marginRight: "5px",
  },
  ButtonRight: {
    marginRight: "10px",
  },
  Button: {
    height: "50px",
    width: "130px",
    fontSize: "20px",
    borderRadius: "25px",
  },
  ApproveButton: {
    color: "white",
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  DisapproveButton: {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred",
    },
  },
  DetailButton: {
    color: "white",
    backgroundColor: "#979797",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
  AvaterAndName: {
    display: "flex",
  },
  NameAndType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  GirdAvatarAndName: {
    paddingRight: "0 !important",
  },
  NavButton: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApprove: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "100px",
    color: "green",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  NavButtonApproveReject: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: "100px",
    justifyContent: "flex-end",
    color: "red",
    ["@media only screen and (max-width: 1100px)"]: {
      marginTop: "5px",
      height: "50px",
    },
  },
  GridContainer: {
    width: "70%",
    ["@media only screen and (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  GirdPurposeAndDateAndTime: {
    paddingLeft: "15px",
  },
  Text: {
    color: "#293A80",
  },
  CrossArea: {
    color: "#C400C6",
  },
  InArea: {
    color: "#0A81AB",
  },
  DateAndTime: {
    ["@media (min-width: 700px) and (max-width: 780px)"]: {
      display: "flex",
    },
  },
  Date: {
    marginRight: "40px",
  },
}));

const InArea = ({
  booking,
  typeOfBooking,
  convertTime,
  convertDate,
  watchDetail,
  onClickApprove,
}) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.Card}`} key={booking.idinAreaCarBooking}>
      <Grid container className={classes.GridContainer}>
        <Grid item xs={12} md={6} className={classes.GirdAvatarAndName}>
          <div className={classes.AvaterAndName}>
            <Avatar
              className={classes.Avatar}
              // src={`${process.env.REACT_APP_URL}image/profile/${booking.user.image}`}
              // alt={booking.user.image}
            />
            <div className={classes.NameAndType}>
              <Typography className={classes.Name}>{booking.name}</Typography>
              <div className={`${classes.TextAndIcon}`}>
                <EmojiTransportationIcon
                  className={`${classes.Icon} ${classes.InArea}`}
                />

                <Typography
                  className={classes.InArea}
                >{`ประเภท : การจองรถในพื้นที่`}</Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          spacing={2}
          alignItems="center"
          className={classes.GirdPurposeAndDateAndTime}
        >
          <Grid item xs={12}>
            <div className={`${classes.TextAndIcon}`}>
              <GpsFixedRoundedIcon className={`${classes.Icon}`} />
              <Typography
                className={`${classes.Text}`}
              >{`วัตถุประสงค์ : ${booking.purpose}`}</Typography>
            </div>
            <div className={classes.DateAndTime}>
              <div className={`${classes.TextAndIcon} ${classes.Date}`}>
                <CalendarTodayIcon className={`${classes.Icon}`} />
                <Typography
                  className={`${classes.Text}`}
                >{`วันที่ใช้ : ${convertDate(
                  booking.departureDate,
                  booking.returnDate
                )}`}</Typography>
              </div>
              <div className={`${classes.TextAndIcon}`}>
                <AccessTimeRoundedIcon className={`${classes.Icon}`} />
                <Typography className={`${classes.Text}`}>
                  {`ระยะเวลา : ${convertTime(
                    booking.startTime,
                    booking.endTime
                  )}`}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {booking.statusApproved === "Success" && (
        <div className={classes.NavButtonApprove}>
          <p>อนุมัติแล้ว</p>
        </div>
      )}
      {booking.statusApproved === "Reject" && (
        <div className={classes.NavButtonApprove}>
          <p>ไม่อนุมัติ</p>
        </div>
      )}
      {booking.statusApproved != "Success" &&
        booking.statusApproved != "Reject" && (
          <div className={classes.NavButton}>
            <IconButton
              aria-label="SearchIcon"
              className={`${classes.DetailButton} ${classes.ButtonRight}`}
              onClick={() => {
                watchDetail(booking.idinAreaCarBooking);
              }}
              size="large"
            >
              <SearchIcon />
            </IconButton>
            <Button
              className={`${classes.ApproveButton} ${classes.ButtonRight} ${classes.Button}`}
              onClick={() => {
                onClickApprove(booking.idinAreaCarBooking, "Success");
              }}
            >
              <CheckIcon className={classes.IconButton} />
              {` อนุมัติ`}
            </Button>
            <Button
              className={`${classes.DisapproveButton} ${classes.Button} ${classes.ButtonRight}`}
              onClick={() => {
                onClickApprove(booking.idinAreaCarBooking, "Reject");
              }}
            >
              <ClearIcon className={classes.IconButton} />
              {` ไม่อนุมัติ`}
            </Button>
          </div>
        )}
    </Card>
  );
};

export default InArea;
