import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  FormControl,
  Switch,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { SnackbarProvider, useSnackbar } from "notistack";

import userService from "../../../../../services/user.service";
import DriverService from "../../../../../services/driverBooking.service";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
  Text: {
    fontSize: "15px",
  },
  partOfTitleName: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-between",
    // marginBottom: "20px",
  },

  partOfNavSwitch: {
    // width: "60%",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
  },
  partOfNavButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  Button: {
    width: "100px",
    marginTop: "20px",
    backgroundColor: "green",
    fontSize: "18px",
    color: "white",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
  },
}));

const ModalManageDriver = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { result: allDriver } = useSelector((state) => state.drivers);
  const [isDriverFromCompany, setIsDriverFromCompany] = useState(
    props.inforManageCar.statusManageCar
      ? props.inforManageCar.isDriverFromCompany
      : true
  );
  const { enqueueSnackbar } = useSnackbar();

  const onClickSubmit = (AnswerFromInput) => {
    console.log(AnswerFromInput);

    AnswerFromInput.id = props.inforManageCar.idDriverBooking;
    AnswerFromInput.isDriverFromCompany = isDriverFromCompany;
    if (!AnswerFromInput.isDriverFromCompany) {
      AnswerFromInput.idDriver = null;
    }
    console.log("prop", props.inforManageCar, AnswerFromInput);
    console.log(AnswerFromInput);

    DriverService.postManageCarDriverBooking(AnswerFromInput).then((res) => {
      if (res.data) {
        props.handleActionInPage();
        props.onCloseHandle();
        enqueueSnackbar("Data saved successfully", { variant: "success" });
      } else {
        enqueueSnackbar("Data saved failed", { variant: "error" });
      }
    });
  };

  return (
    <Modal
      className={`${classes.Modal}`}
      open={props.open}
      onClose={props.onCloseHandle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={`${classes.Paper}`}>
        <div className={`${classes.partOfTitle}`}>
          <div className={`${classes.partOfTitleName}`}>
            <DriveEtaIcon className={`${classes.IconCar}`} />
            <Typography className={`${classes.Title}`}>เลือกคนขับรถ</Typography>
            {isDriverFromCompany ? (
              <Typography className={`${classes.Title}`}>ในบริษัท</Typography>
            ) : (
              <Typography className={`${classes.Title}`}>
                จากบริษัทอื่น
              </Typography>
            )}
          </div>
          <div className={`${classes.partOfNavSwitch}`}>
            <Button
              variant="contained"
              onClick={() => {
                setValue("isDriverFromCompany", !isDriverFromCompany);
                setIsDriverFromCompany(!isDriverFromCompany);
              }}
            >
              {isDriverFromCompany
                ? "เลือกคนขับจากบริษัทอื่น"
                : "เลือกคนขับในบริษัท"}
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onClickSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {isDriverFromCompany ? (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="idDriver">เลือกคนขับรถ</InputLabel>

                  <Controller
                    render={(propsInController) => (
                      <Select
                        labelId="idDriver"
                        id="idDriver"
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        label="ชื่อคนขับรถ"
                      >
                        {allDriver &&
                          allDriver.map((driver) => (
                            <MenuItem key={driver.idUser} value={driver.idUser}>
                              {driver.FullName}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                    name="idDriver"
                    control={control}
                    defaultValue={props.inforManageCar?.idDriver}
                  />
                </FormControl>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={(propsInController) => (
                        <TextField
                          {...propsInController.field}
                          label="ชื่อคนขับรถ"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      name="nameDriver"
                      control={control}
                      defaultValue={props.inforManageCar?.nameDriver}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      render={(propsInController) => (
                        <TextField
                          {...propsInController.field}
                          label="เบอร์ติดต่อคนขับรถ"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      name="phoneDriver"
                      control={control}
                      defaultValue={props.inforManageCar?.phoneDriver}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="Note"
                label="หมายเหตุ"
                variant="outlined"
                fullWidth
                defaultValue={props.inforManageCar?.note}
                multiline
                rows={3}
                inputProps={{
                  ...register("note", { value: props.inforManageCar?.note }),
                }}
              />
            </Grid>
          </Grid>
          <div className={`${classes.partOfNavButton}`}>
            <Button className={`${classes.Button}`} type="submit">
              ตกลง
            </Button>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};

export default ModalManageDriver;
