import React from "react";

import { styled } from '@mui/styles';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@mui/material';

const ButtonStyled = styled(Button)({
    backgroundColor:"green",
    color:"white",
    "&:hover":{
        backgroundColor:"green",
    },
});

const DialogWarningSeats = (props) => {
    return(
        <Dialog
            open={props.open.warning}
            onClose={props.handleClose}
            aria-labelledby="Not_Seats_Enough"
            aria-describedby="Detail_Not_Seats_Enough"
        >
            <DialogTitle id="Not_Seats_Enough">
                <Typography style={{fontSize:"18px"}}>
                    คำเตือน
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{fontSize:"18px", wordBreak:"break-word"}}>
                    รถคันนี้มีจำนวนที่นั่งไม่พอกับจำนวนผู้โดยสาร
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonStyled onClick={props.handleClose}>
                    ตกลง
                </ButtonStyled>
            </DialogActions>
        </Dialog>
    );
} ;

export default DialogWarningSeats;