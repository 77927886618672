import React from "react";
import { Button, Stack, styled, Typography, IconButton } from "@mui/material";

import Modal from "../../../shared/Modal";

const Root = styled("div")(({ theme }) => ({
  padding: 16,
  width: 480,
  maxWidth: "100%",
  boxSizing: "border-box",
  // [theme.breakpoints.down("sm")]: {
  //     width: "100%",
  // }
  ["& .closeIcon"]: {
    width: 20,
    height: 20,
    fontSize: 20,
  },
}));

const ConfirmApproveModal = ({
  open,
  onClose,
  onChangeStatusHandler,
  index,
}) => {
  return (
    <Modal open={open} onClose={onClose} disableFullscreen>
      <div
        style={{ width: "100%", height: 6, backgroundColor: "#4BB543" }}
      ></div>

      <Root>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <i className="far fa-times closeIcon"></i>
          </IconButton>
        </Stack>
        <Stack spacing={2}>
          {/* <Typography textAlign="center" fontSize={20}>ยืนยันการดำเนินการ</Typography> */}
          <Typography textAlign="center" fontSize={20}>
            คุณต้องการ<span style={{ color: "#2e7d32" }}>อนุมัติ</span>คำขอ ?
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#4BB543", // สีเขียว
                "&:hover": {
                  backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                },
              }}
              onClick={() => {
                onChangeStatusHandler(index, "approved");
                onClose();
              }}
            >
              อนุมัติ
            </Button>
          </Stack>
        </Stack>
      </Root>
    </Modal>
  );
};

export default ConfirmApproveModal;
