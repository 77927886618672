import React, { useState, useEffect } from "react";
import Modal from "../../../../shared/Modal";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Container,
  Avatar,
  Button,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Typography,
  Stack,
  styled,
  Divider,
} from "@mui/material";

import maintenanceService from "../../../../../../services/maintenance.service";

const StyledSelectTechnician = styled(TextField)(({ theme }) => ({
  maxWidth: 250,
  ["& .MuiSelect-select"]: {
    display: "flex",
    alignItems: "center",
  },
}));

const AllowModal = ({ open, onClose, onConfirm }) => {
  const [technicians, setTechnicians] = useState([]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      technician: [""],
    },
  });
  const {
    fields: technicianField,
    append: technicianAppend,
    remove: technicianRemove,
  } = useFieldArray({
    control,
    name: "technician",
  });

  const onSubmit = (data) => {
    let hasError = false;

    const technicianData = getValues("technician");

    technicianData.map((t, index) => {
      if (index != technicianData.findIndex((tt) => tt === t)) {
        hasError = true;
        setError(`technician.${index}`, {
          type: "duplicate",
          message: "ไม่สามารถเลือกช่างซ้ำกันได้",
        });
      }
    });

    if (hasError) {
		console.log("hasError")
      return;
    } else {
      onConfirm(getValues("technician"));
      onClose();
    }
  };

  const onTechnicianAppend = () => {
    console.log(getValues("technician"));
    if (!getValues("technician").includes("")) {
      technicianAppend("");
    }else if(getValues("technician")[0] == ""){
		technicianAppend("");
	}
  };

  // const technician = [
  // 	{
  // 		id: 1,
  // 		fullName: "นายน้อย หอมจันทร์",
  // 		phoneNumber: "0944444444",
  // 		types: ["ช่างไฟฟ้า"]
  // 	},
  // 	{
  // 		id: 2,
  // 		fullName: "นายเรือง ส่องแสง",
  // 		phoneNumber: "0955555555",
  // 		types: ["ช่างไฟฟ้า","ช่างประปา"]
  // 	},
  // 	{
  // 		id: 3,
  // 		fullName: "นายเรืองแสง ส่องสว่าง",
  // 		phoneNumber: "0955555555",
  // 		types: ["ช่างไฟฟ้า","ช่างประปา","ช่างก่อสร้าง"]
  // 	},
  // ]

  const checkSelectedTechnician = (id) => {
    return getValues("technician")?.includes(id) || false;
  };

  const showInformation = (id) => {

    const index = technicians.findIndex(
      (t) => t.idMaintenanceTechnicians === id
    );
    return (
      <Stack
        direction="row"
        spacing={2}
        style={{ flexFlow: "row wrap" }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {/* <Avatar/> */}
        <div>
          <Typography style={{ color: "#9e9e9e", fontSize: 14 }}>
            ชื่อ-นามสกุล
          </Typography>
          <Typography>{technicians[index].fullName}</Typography>
        </div>
        <div>
          <Typography style={{ color: "#9e9e9e", fontSize: 14 }}>
            เบอร์โทร
          </Typography>
          <Typography>{technicians[index].phoneNumber}</Typography>
        </div>
        <div>
          <Typography style={{ color: "#9e9e9e", fontSize: 14 }}>
            ประเภทช่างซ่อม
          </Typography>
          <Typography>{technicians[index].types}</Typography>
        </div>
      </Stack>
    );
  };

  useEffect(() => {
    maintenanceService.getAllTechnicians().then((res) => {
      console.log(res.data.data);
      setTechnicians(res.data.data);
    });
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Container maxWidth="sm" fullWidth style={{ padding: "16px" }}>
        <Typography>เลือกช่างผู้ดูแลงาน</Typography>
        <Divider style={{ margin: "12px 0 16px" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {technicianField.map((field, index) => (
              <Grid key={field.id} item xs={12}>
                <Controller
                  control={control}
                  name={`technician.${index}`}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Stack spacing={2}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <StyledSelectTechnician
                          {...field}
                          select
                          fullWidth
                          size="small"
                          defaultValue={""}
                          helperText={
							
                            errors.technician && errors.technician[index]
                              ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                              : false
                          }
                          error={errors.technician && errors.technician[index]}
                        >
                          {technicians.map((t, i) => (
                            <MenuItem
                              key={`technician_${i}`}
                              value={t.idMaintenanceTechnicians}
                            >
                              {t.image ? (
                                <Avatar
                                  src={`${process.env.REACT_APP_URL}image/maintenance/technician/${t.image}`}
                                  style={{ marginRight: 8 }}
                                />
                              ) : (
                                <Avatar style={{ marginRight: 8 }} />
                              )}
                              {/* <Avatar style={{marginRight: 8}}/> */}
                              <Stack>
                                <span>{t.fullName}</span>
                                <span
                                  style={{ fontSize: 14, color: "#9e9e9e" }}
                                >
                                  {t.types}
                                </span>
                              </Stack>
                            </MenuItem>
                          ))}
                        </StyledSelectTechnician>
                        {index > 0 && (
                          <IconButton
                            style={{
                              width: 40,
                              height: 40,
                              color: "#e53935",
                              backgroundColor: "#ffd6d5",
                            }}
                            onClick={() => {
                              technicianRemove(index);
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </IconButton>
                        )}
                      </Stack>
                      {field.value && showInformation(field.value)}
                    </Stack>
                  )}
                />
              </Grid>
            ))}

            <Grid item xs={12} container justifyContent="center">
              <IconButton
                style={{
                  width: 40,
                  height: 40,
                  color: "#fff",
                  backgroundColor: "#3f51b5",
                }}
                onClick={onTechnicianAppend}
              >
                <i className="fas fa-plus"></i>
              </IconButton>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#43a047" }}
              >
                ยืนยัน
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Modal>
  );
};

export default AllowModal;
