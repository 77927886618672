import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyleButtonBlue = styled(Button)(({ variant, color }) => ({
  ...(variant === "contained" && {
    borderColor: "#00bb77",
    color: "#ffffff",
    backgroundColor: "#00bb77",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#009e67",
      backgroundColor: "#009e67",
    },
  }),
  ...(variant === "contained" &&
    color === "error" && {
      borderColor: "red",
      color: "#ffffff",
      backgroundColor: "red",
      borderRadius: 8,
      "&:hover": {
        borderColor: "darkred",
        backgroundColor: "darkred",
      },
    }),
  ...(variant === "outlined" && {
    borderColor: "#46cbe2",
    backgroundColor: "transparent",
    color: "#46cbe2",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#46cbe2",
    },
  }),
  ...(variant === "outlined" &&
    color === "secondary" && {
      borderColor: "#DAE2ED",
      color: "#757575",
      backgroundColor: "transparent",
      borderRadius: 8,
      "&:hover": {
        borderColor: "#C7D0DD",
        backgroundColor: "#F3F6F9",
      },
    }),
  ...(variant === "text" && {
    backgroundColor: "transparent",
    color: "#00bb77",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#1976d20a",
    },
  }),
  ...(variant === "text" &&
    color === "secondary" && {
      backgroundColor: "transparent",
      color: "#212b36",
      borderRadius: 8,
      "&:hover": {
        backgroundColor: "#1976d20a",
      },
    }),
  ...(variant === "contained" &&
    color === "warning" && {
      borderColor: "#ffab00",
      color: "#ffffff",
      backgroundColor: "#ffab00",
      borderRadius: 8,
      "&:hover": {
        borderColor: "#b17801",
        backgroundColor: "#b17801",
      },
    }),
}));

const ButtonBlue = forwardRef((props, ref) => {
  return (
    <StyleButtonBlue
      ref={ref}
      type={props.type}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      style={props.style}
      size={props.size}
      variant={props.variant}
      onClick={props.onClick}
      component={props.component}
      to={props.to}
      className={props.className}
      color={props.color}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </StyleButtonBlue>
  );
});

export default ButtonBlue;
