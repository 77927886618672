import React from "react";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ButtonBlue from "../buttonBlue";

const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .check-icon-label": {
        fontSize: 90,
        color: "#4BB543",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#2e7d32",
          fontSize: 20,
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
}));

const ConfirmModal = (props) => {
  const classes = useStyles();
  const { open, onCloseConfirmModalHandle, text, label, onClickConfirm } =
    props;

  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={onCloseConfirmModalHandle}
    >
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#4BB543" }}
        ></div>
        <div className="content">
          <CheckCircleOutlineRoundedIcon className="check-icon-label" />
          <Typography variant="h6" className={classes.title}>
            คุณแน่ใจใช่ไหม?
          </Typography>
          {label && <Typography className={classes.label}>{label}</Typography>}
          {text && (
            <Typography
              className="detail"
              variant="h6"
              style={{ color: "#2e7d32" }}
              component="span"
            >
              {text}
            </Typography>
          )}

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={onCloseConfirmModalHandle}
            >
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              sx={{
                color: "white",
                backgroundColor: "#4BB543", // สีเขียว
                "&:hover": {
                  backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                },
              }}
              variant="contained"
              onClick={onClickConfirm}
            >
              ยืนยัน
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmModal;
