import React, { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { styled } from '@mui/styles';
import {
    Grid, Typography, Divider
} from '@mui/material';

require('dayjs/locale/th');
dayjs.locale('th');

const GridContainer = styled(Grid)({
    width:"100%"
});

const Text = styled(Typography)(({theme})=>({
    fontSize:"20px",
}));

const DividerStyled = styled(Divider)(({theme})=>({
    height: "1px",
    width: "100%",
    marginTop:"20px",
    marginBottom:"20px",
    backgroundColor: "#DDDDDD",
}));

const DetailDeliveryCar = props => {
    const [booking, setBooking] = useState(null)

    useEffect(() => {
        if(props.booking){
            setBooking(props.booking);
        }
    }, [])

    return (
        <Fragment>
            {booking && 
                <Fragment>
                    <GridContainer container spacing={1}>
                        <Grid item xs={12} md={6}> 
                            <Text>{`ผู้รับ : ${booking.nameRecipient}`}</Text>
                        </Grid>
                        <Grid item xs={12} md={6}> 
                            <Text>{`เบอร์โทรศัพท์ผู้รับ : ${booking.telephoneMobileRecipient}`}</Text>
                        </Grid>
                    </GridContainer>
                    <DividerStyled />
                    <GridContainer container spacing={1}>
                        <Grid item xs={12} sm={6}> 
                            <Text>{`ต้นทาง : ${booking.fromPlace}`}</Text>
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Text>{`ปลายทาง : ${booking.toPlace}`}</Text>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text>
                                {`วันที่เริ่ม : ${dayjs(booking.date).format("DD MMMM YYYY")}`}
                            </Text>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Text>
                                {`เวลา : ${dayjs(booking.startTime).format(" HH.mm น.")} - ${dayjs(booking.endTime).format(" HH.mm น.")}`}
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text>
                                {`รายละเอียด : ${booking.detail ? booking.detail : "-"}`}
                            </Text>
                        </Grid>
                    </GridContainer>
                </Fragment>
            }
        </Fragment>
    );
};

export default DetailDeliveryCar;