import React, { useEffect, useState } from "react";
import TruckLocation from "../../../user/sampleshuttle/NewSample/CardDetailSample/showMap/Icon/truck.png";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import siteService from "../../../../../services/site.service";

const Direction = (props) => {
  //   useEffect(() => {
  //     if (props.isLocationDriver) {
  //       setLocationDriver(props.isLocationDriver);
  //     }
  //   }, [props.isLocationDriver]);

  //   useEffect(() => {
  //     if (props.siteFrom) {
  //       setSiteFrom(props.siteFrom);
  //     }
  //   }, [props.siteFrom]);

  //   useEffect(() => {
  //     if (isLocationDriver && siteFrom) {
  //       console.log("directionsService");
  //       const directionsService = new window.google.maps.DirectionsService();

  //       directionsService.route(
  //         {
  //           origin: new window.google.maps.LatLng(
  //             isLocationDriver[0].Lat,
  //             isLocationDriver[0].Long
  //           ),
  //           destination: new window.google.maps.LatLng(
  //             siteFrom.Lat,
  //             siteFrom.Long
  //           ),
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         },
  //         (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             setDirections(result);
  //           } else {
  //             console.error(`error fetching directions ${result}`);
  //           }
  //         }
  //       );
  //     }
  //   }, [isLocationDriver, siteFrom]);

  return (
    <>
      {props.directions && (
        <DirectionsRenderer
          directions={props.directions}
          options={{
            suppressMarkers: true,
            polylineOptions: { strokeColor: "#00995c", strokeWeight: 5 },
          }}
        />
      )}
    </>
  );
};

export default Direction;
