import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/styles";

import makeStyles from "@mui/styles/makeStyles";
import {
  Modal,
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../utils/googleMapAssets";
import SiteService from "../../../../../services/site.service";
import MarkerDriver from "./MarkerDriver";
const placesLibrary = ["places"];

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "800px",
    height: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
}));
const AlertMessage = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  fontSize: "16px",
  color: "red",
});
const ModalShowmap = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, control, watch, getValues, setValue } =
    useForm();
  const [isLocationDriver, setLocationDriver] = useState([
    { Lat: props.inforInCar.LatDriver, Long: props.inforInCar.LngDriver },
  ]);
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });
  useEffect(() => {
    if (props.inforInCar != null) {
      SiteService.getLocationDriverById(props.inforInCar.idDriver)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([{ Lat: 0, Long: 0 }]);
        });
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const defaultProps = {
    center: {
      lat: parseFloat(isLocationDriver[0].Lat),
      lng: parseFloat(isLocationDriver[0].Long),
      // lat: parseFloat(12.5),
      // lng: parseFloat(1.5),
    },
    zoom: 13,
  };

  return (
    <>
      {isLoaded && (
        <Modal
          className={`${classes.Modal}`}
          open={props.open}
          onClose={props.onCloseHandle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={`${classes.Paper}`}>
            <div className={`${classes.partOfTitle}`}>
              <DriveEtaIcon className={`${classes.IconCar}`} />
              <Typography className={`${classes.Title}`}>แสดงแผนที่</Typography>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
              >
                {props.inforInCar.idDriver ? (
                  <MarkerDriver inforInCar={props.inforInCar} />
                ) : (
                  <AlertMessage>ไม่พบข้อมูล</AlertMessage>
                )}
              </GoogleMap>
            </div>
          </Paper>
        </Modal>
      )}
    </>
  );
};
export default ModalShowmap;
