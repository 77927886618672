import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar } from "notistack";

import clsx from "clsx";

import { styled } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import {
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  Container,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FormInformant from "./formInformat";
import FormFlight from "./formFlight";
import FormPurpose from "./formPurpose";
import FormDetailOfFight from "./formDetailOfFight";
import FormApprove from "./formApprove";
import DialogWarningSeats from "./dialogWarningSeats";
import NavButton from "../../../../shared/NavButton/NavButtonForm";
import DialogShowDetailBooking from "./dialogShowDetailBooking";
import {
  getCrossAreaCarBookingByStartDate,
  getCrossAreaCarBookingByStartDateAndEndDate,
} from "../../../../../../actions/crossAreaCarBooking";
import { getEmployeesByCompany } from "../../../../../../actions/employee";
import { fi } from "date-fns/locale";
import vehicleBrandAndModelService from "../../../../../../services/vehicleBrandAndModel.service";
import CrossAreaCarBookingServices from "../../../../../../services/crossAreaCarBooking.service";
import { set } from "date-fns";
const DivCoverReturn = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const DivStepperStyled = styled("div")(({ theme }) => ({
  width: "100%",
  // backgroundColor: "transparent",
  // boxShadow: "none",
  // width: "100%",
  // color: "#212b36",
  // backgroundColor: "#ffffffcc",
  // backdropFilter: "blur(6px)",
  // position: "fixed",
  // top: "64px",
  // borderBottom: "1px solid black",
  marginBottom: "20px",
  // padding: "15px 0",
  // zIndex: "100",
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

const DivCoverForm = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  // marginTop: "20px",
  // paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const FormStyled = styled("form")({
  width: "80%",
});

const DivSpace = styled("div")({
  height: "150px",
});

const DivHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "20px",
});

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
}));

const BackButton = styled(Button)(({ theme }) => ({
  // position: "absolute",
  color: "black",
  fontSize: "20px",
  // left: "30px",
}));

const EditDetailCrossAreaCarBooking = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const MobileResponsive = useMediaQuery("(max-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [booking, setBooking] = useState(null);
  const [fromPlacePassenger, setFromPlacePassenger] = useState(null);
  const [fromPlaceReturnPassenger, setFromPlaceReturnPassenger] =
    useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertCheckNumberOfPassenger, setOpenAlertCheckNumberOfPassenger] =
    useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [openDialog, setOpenDialog] = useState({
    warning: false,
    detailBooking: false,
  });
  const { user: AllUsers } = useSelector((state) => state.auth);
  const { result: allEmployees } = useSelector((state) => state.employees);

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/admin/car_management");
    } else {
      if (!allEmployees) {
        dispatch(getEmployeesByCompany(AllUsers.idUser));
      }
      setBooking(props.location.state.booking);
      const booking = props.location.state.booking;
      const passenger = props.location.state.passenger;
      const startTime = SplitTimeReverse(booking.startTime);
      const endTime = SplitTimeReverse(booking.endTime);
      vehicleBrandAndModelService
        .getVehicleBrandAndModelById(booking.idVehicleBrandAndModel)
        .then((res) => {
          if (res.data) {
            setVehicle(res.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setValue("idCrossAreaCarBooking", booking.idCrossAreaCarBooking);
      setValue("idUser", booking.idUser);
      setValue("name", booking.name);
      setValue("telephoneMobile", booking.telephoneMobile);
      setValue("email", booking.email);
      setValue("section", booking.section);
      setValue("department", booking.department);
      setValue("flight", booking.flight);
      setValue("fromPlace", booking.fromPlace);
      setValue("toPlace", booking.toPlace);
      setValue("numberOfPassenger", parseInt(booking.numberOfPassenger));
      setValue("departureDate", booking.departureDate);
      setValue("startTime", startTime);
      setValue("endTime", endTime);
      setValue("purpose", booking.purpose);
      setValue("idVehicleBrandAndModel", booking.idVehicleBrandAndModel);
      setValue("gettingPoint", passenger.length > 1 ? "manyPoint" : "onePoint");
      let fromPlacePassenger = [];
      let fromPlaceReturnPassenger = [];
      // console.log(booking);
      // console.log(passenger);
      for (let i = 0; i < passenger.length; i++) {
        const option = {
          firstname_TH: passenger[i].name.split(" ")[0],
          lastname_TH: passenger[i].name.split(" ")[1],
        };
        setValue(
          `listPassenger.person${i + 1}.idCrossAreaCarPassenger`,
          passenger[i].idCrossAreaCarPassenger
        );
        setValue(`listPassenger.person${i + 1}.option`, option);
        setValue(`listPassenger.person${i + 1}.name`, passenger[i].name);
        setValue(
          `listPassenger.person${i + 1}.telephoneMobile`,
          passenger[i].telephoneMobile
        );
        setValue(`listPassenger.person${i + 1}.email`, passenger[i].email);
        setValue(`listPassenger.person${i + 1}.company`, passenger[i].company);
        setValue(
          `listPassenger.person${i + 1}.costCenter`,
          passenger[i].costCenter
        );
        setValue(
          `listPassenger.person${i + 1}.costElement`,
          passenger[i].costElement
        );
        setValue(
          `listPassenger.person${i + 1}.fromPlace`,
          passenger[i].fromPlace
        );
        fromPlacePassenger.push(passenger[i].fromPlace);
        if (booking.flight === "twoWay") {
          setValue(
            `listPassenger.person${i + 1}.fromPlaceReturn`,
            passenger[i].fromPlaceReturn
          );
          fromPlaceReturnPassenger.push(passenger[i].fromPlaceReturn);
        }
      }
      setFromPlacePassenger(fromPlacePassenger);
      setFromPlaceReturnPassenger(fromPlaceReturnPassenger);
      if (booking.flight === "twoWay") {
        const returnStartTime = SplitTimeReverse(booking.returnStartTime);
        const returnEndTime = SplitTimeReverse(booking.returnEndTime);
        setValue("toPlaceReturn", booking.toPlaceReturn);
        setValue("fromPlaceReturn", booking.fromPlaceReturn);
        setValue(
          "numberOfPassengerReturn",
          parseInt(booking.numberOfPassengerReturn)
        );
        setValue("returnDate", booking.returnDate);
        setValue("returnStartTime", returnStartTime);
        setValue("returnEndTime", returnEndTime);
      }
    }
  }, []);

  const listStepper = [
    "รายละเอียดการจองรถ",
    "ระบุสถานที่ของแต่ละบุคคล",
    "ระบุบริษัทและคนที่อนุมัติ",
  ];

  const checkSeats = () => {
    if (
      parseInt(getValues("numberOfPassenger")) > parseInt(getValues("capacity"))
    ) {
      setOpenDialog({ ...openDialog, warning: true });
      return false;
    } else {
      return true;
    }
  };

  const checkNumberOfPassenger = () => {
    if (
      parseInt(getValues("numberOfPassengerReturn")) <= 0 ||
      parseInt(getValues("numberOfPassenger")) <= 0
    ) {
      setOpenAlertCheckNumberOfPassenger(true);
      return false;
    } else {
      setOpenAlertCheckNumberOfPassenger(false);
      return true;
    }
  };

  const onSubmitNextPage = (data) => {
    onClickNext();
  };

  const onClickBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
    window.scroll(0, 0);
  };

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleCloseCheckNumberOfPassenger = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertCheckNumberOfPassenger(false);
  };

  const checkListPassenger = () => {
    let isNotSpecified = [];

    if (getValues("gettingPoint") === "onePoint") {
      if (
        getValues(`listPassenger.person${1}.option`) === undefined ||
        getValues(`listPassenger.person${1}.option`) === null
      ) {
        isNotSpecified.push(1);
      }
    } else {
      for (let index = 1; index <= getValues("numberOfPassenger"); index++) {
        if (
          getValues(`listPassenger.person${index}.option`) === undefined ||
          getValues(`listPassenger.person${index}.option`) === null
        ) {
          isNotSpecified.push(index);
        }
      }
    }
    if (isNotSpecified.length > 0) {
      handleClick();
      return false;
    } else {
      return true;
    }
  };

  const onClickNext = () => {
    let Pass = true;
    let checkPassenger = true;

    if (activeStep === 1) {
      Pass = checkNumberOfPassenger();
    }
    if (activeStep === 2) {
      checkPassenger = checkListPassenger();
    }
    if (Pass && checkPassenger && activeStep !== 3) {
      setActiveStep((preActiveStep) => preActiveStep + 1);
    }
    if (activeStep === 3) {
      setOpenDialog({ ...openDialog, detailBooking: true });
    }
    if (checkPassenger && Pass) {
      window.scroll(0, 0);
    }
  };

  const SplitTime = (time) => {
    let splitTime = time.split(":");
    let TimeSet = new Date().setHours(splitTime[0], splitTime[1]);
    let result = new Date(TimeSet);
    return result;
  };

  const SplitTimeReverse = (dateString) => {
    const date = new Date(dateString);
    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minutes}`;
  };

  const OnClickSubmitBooking = (data) => {
    delete data.Approved;
    delete data.indexCar;
    data.numberOfPassenger = parseInt(data.numberOfPassenger);
    data.startTime = SplitTime(data.startTime);
    data.endTime = SplitTime(data.endTime);
    data.totalPrice = getValues("totalPrice");
    if (getValues("flight") !== "twoWay") {
      data.fromPlaceReturn = null;
      data.toPlaceReturn = null;
      data.numberOfPassengerReturn = 0;
      data.returnDate = null;
      data.returnStartTime = null;
      data.returnEndTime = null;
    } else {
      data.numberOfPassengerReturn = parseInt(data.numberOfPassengerReturn);
      data.returnStartTime = SplitTime(data.returnStartTime);
      data.returnEndTime = SplitTime(data.returnEndTime);
    }

    CrossAreaCarBookingServices.postUpdateCrossAreaCarBooking(data).then(
      (res) => {
        if (res.status === 200) {
          enqueueSnackbar("Create Booking Success", { variant: "success" });
          fetchData();
          props.history.push("/admin/car_management/cross_area");
        } else {
          enqueueSnackbar("Create Booking Failed", { variant: "error" });
        }
      }
    );
  };

  const fetchData = () => {
    if (props.endDate) {
      let date = {
        startDate: new Date(props.startDate),
        endDate: new Date(props.endDate),
      };
      dispatch(getCrossAreaCarBookingByStartDateAndEndDate(date));
    } else {
      let date = {
        startDate: new Date(props.startDate),
      };
      dispatch(getCrossAreaCarBookingByStartDate(date));
    }
  };

  return (
    <DivCoverReturn>
      {booking && (
        <DivCoverForm className={clsx({ Mobile: MobileResponsive })}>
          <DivHead>
            <BackButton onClick={() => props.history.goBack()}>
              <ArrowBackIosNewIcon /> Back
            </BackButton>
            <Title>แก้ไขการจองรถข้ามพื้นที่เฉพาะ</Title>
            <div></div>
          </DivHead>
          <DivStepperStyled>
            <Stepper activeStep={activeStep - 1} alternativeLabel>
              {listStepper.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </DivStepperStyled>
          {activeStep === 1 && (
            <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
              <FormInformant
                register={register}
                setValue={setValue}
                errors={errors}
              />

              <FormFlight
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                booking={booking}
                errors={errors}
              />

              <FormPurpose register={register} errors={errors} />
              <NavButton
                type="button"
                numberOfPage={activeStep}
                onClickBackPageHandler={onClickBack}
              />
            </FormStyled>
          )}
          {/* {activeStep === 2 && (
            <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
              <FormCar
                getValues={getValues}
                setValue={setValue}
                control={control}
                watch={watch}
              />
              <NavButton
                type="button"
                numberOfPage={activeStep}
                onClickBackPageHandler={onClickBack}
              />
            </FormStyled>
          )} */}
          {activeStep === 2 && (
            <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
              <FormDetailOfFight
                register={register}
                fromPlacePassenger={fromPlacePassenger}
                fromPlaceReturnPassenger={fromPlaceReturnPassenger}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
              <NavButton
                type="button"
                numberOfPage={activeStep}
                onClickBackPageHandler={onClickBack}
              />
              <DivSpace />
            </FormStyled>
          )}
          {activeStep === 3 && (
            <FormStyled onSubmit={handleSubmit(onSubmitNextPage)}>
              <FormApprove
                idApproved={booking.idApproved}
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
              />
              <NavButton
                type="submit"
                numberOfPage={activeStep + 1}
                onClickBackPageHandler={onClickBack}
              />
            </FormStyled>
          )}
        </DivCoverForm>
      )}
      <DialogWarningSeats
        open={openDialog}
        handleClose={() => setOpenDialog({ ...openDialog, warning: false })}
      />
      {vehicle && (
        <DialogShowDetailBooking
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          vehicle={vehicle}
          open={openDialog}
          handleClose={() =>
            setOpenDialog({ ...openDialog, detailBooking: false })
          }
          OnClickSubmitBooking={handleSubmit(OnClickSubmitBooking)}
        />
      )}
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          กรุณากรอกข้อมูลให้ครบ
        </Alert>
      </Snackbar>
      <Snackbar
        open={openAlertCheckNumberOfPassenger}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={5000}
        onClose={handleCloseCheckNumberOfPassenger}
      >
        <Alert
          onClose={handleCloseCheckNumberOfPassenger}
          variant="filled"
          severity="error"
        >
          จำนวนผู้โดยสารต้องมากกว่า 0 คน
        </Alert>
      </Snackbar>
    </DivCoverReturn>
  );
};

export default EditDetailCrossAreaCarBooking;
