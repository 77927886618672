import {
  DRIVER_BOOKINGS_FETCHING,
  DRIVER_BOOKINGS_FAILED,
  DRIVER_BOOKINGS_SUCCESS,
} from "./types";

import DriverBookingService from "../services/driverBooking.service";

export const getAllDriverBookingByIdUser = (idUser) => async (dispatch) => {
  try {
    dispatch({
      type: DRIVER_BOOKINGS_FETCHING,
    });
    const res = await DriverBookingService.getAllDriverBookingByIdUser(idUser);
    if (res) {
      dispatch({
        type: DRIVER_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DRIVER_BOOKINGS_FAILED,
    });
  }
};

export const getDriverBookingByIdDriver = (idDriver) => async (dispatch) => {
  try {
    dispatch({
      type: DRIVER_BOOKINGS_FETCHING,
    });
    const res = await DriverBookingService.getDriverBookingByIdDriver(idDriver);
    if (res) {
      dispatch({
        type: DRIVER_BOOKINGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DRIVER_BOOKINGS_FAILED,
    });
  }
};

export const getDriverBookingByFilterByIdDriver =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: DRIVER_BOOKINGS_FETCHING,
      });
      const res = await DriverBookingService.getDriverBookingByFilterByIdDriver(
        filter
      );
      if (res) {
        dispatch({
          type: DRIVER_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DRIVER_BOOKINGS_FAILED,
      });
    }
  };

export const getDriverBookingByFilterByIdUser =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: DRIVER_BOOKINGS_FETCHING,
      });
      const res = await DriverBookingService.getDriverBookingByFilterByIdUser(
        filter
      );
      if (res) {
        dispatch({
          type: DRIVER_BOOKINGS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: DRIVER_BOOKINGS_FAILED,
      });
    }
  };
