import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";

import { styled } from "@mui/styles";
import { Grid, Typography, Divider } from "@mui/material";
import EmployeeService from "../../../../../services/employee.service.js";

require("dayjs/locale/th");
dayjs.locale("th");

const GridContainer = styled(Grid)({
  width: "100%",
});

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const DetailInAreaCar = (props) => {
  const [booking, setBooking] = useState(null);
  const [isEmployee, setEmployee] = useState([]);

  useEffect(() => {
    if (props.booking) {
      setBooking(props.booking);
    }
  }, []);

  EmployeeService.getEmployeesByIdApproved(props.booking.idApprovedUser)
    .then((res) => {
      if (res.status === 200) {
        setEmployee(res.data);
      } else {
        setEmployee([]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return (
    <Fragment>
      {booking && (
        <Fragment>
          <GridContainer container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Text>{`ต้นทาง : ${booking.fromPlace}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>{`ปลายทาง : ${booking.toPlace}`}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`วันที่เริ่ม : ${dayjs(booking.departureDate).format(
                  "DD MMMM YYYY"
                )}`}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                {`เวลา : ${dayjs(booking.startTime).format("HH:MM")} - ${dayjs(
                  booking.endTime
                ).format("HH:MM")}`}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Text>{`Note : ${booking.note ? booking.note : "-"}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12}>
              <Text>บริษัท : {booking.company}</Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                Cost Center :{" "}
                {booking.costCenter && booking.costCenter !== ""
                  ? booking.costCenter
                  : " -"}
              </Text>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text>
                Cost Element :{" "}
                {booking.costElement && booking.costElement !== ""
                  ? booking.costElement
                  : " -"}
              </Text>
            </Grid>
          </GridContainer>
          {!props.isManager && (
            <>
              <DividerStyled />
              {isEmployee.map((employee, index) => (
                <Fragment key={employee.idEmployee}>
                  <GridContainer container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Text>{`ชื่อผู้อนุมัติ : ${isEmployee[0].firstname_TH} ${isEmployee[0].lastname_TH}`}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>{`แผนก : ${
                        isEmployee[0].departmentName &&
                        isEmployee[0].departmentName !== ""
                          ? isEmployee[0].departmentName
                          : "ไม่ระบุ"
                      }`}</Text>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Text>{`บริษัท : ${isEmployee[0].companyName}`}</Text>
                    </Grid>
                  </GridContainer>
                </Fragment>
              ))}
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailInAreaCar;
