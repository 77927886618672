import React, { useState } from "react";
import {
  styled,
  Typography,
  Grid,
  Chip,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const StyledDropZone = styled("label")(({ theme }) => ({
  flex: 1,
  position: "relative",
  ["& .MuiChip-root"]: {
    marginRight: 8,
    marginBottom: 8,
    color: "#fff",
    cursor: "default",
    backgroundColor: theme.palette.primary.main,
    ["&:hover"]: {
      backgroundColor: theme.palette.primary.main,
    },
    ["& .MuiSvgIcon-root"]: {
      ["&:hover"]: {
        color: "#fff",
      },
    },
  },
}));

const ImagePreview = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
});

const DropZone = ({
  name,
  setValue,
  getValues,
  maxFile,
  maxSize,
  onlyImage,
  defaultValue,
  fileUrl,
}) => {
  console.log(name);
  const [uploadFiles, setUploadFiles] = useState(defaultValue || []);
  const [fileUrls, setFileUrls] = useState(fileUrl || []);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const onFileHandle = (files) => {
    const validateFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (uploadFiles.length + i + 1 > (maxFile || 5)) {
        continue;
      }
      if (files[i].size > (1048576 * maxSize || 10485760)) {
        // check if larger than maxSize
        continue;
      }
      if (
        onlyImage &&
        !["image/jpg", "image/jpeg", "image/png"].includes(files[i].type)
      ) {
        console.log(files[i]);
        console.log("onlyImage", onlyImage);
        continue;
      }
      validateFiles.push(files[i]);
    }
    setUploadFiles([...uploadFiles, ...validateFiles]);

    setValue(name, [...uploadFiles, ...validateFiles]);
    // console.log("validateFiles", validateFiles);
  };

  const onDragHandler = (event) => {
    event.preventDefault();
  };

  const onDropFileHandler = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    onFileHandle(files);
  };

  const onChangeFileHandler = (event) => {
    event.preventDefault();
    const files = event.target.files;
    // console.log(files);
    onFileHandle(files);
    event.target.value = null;
  };

  const onDeleteFile = (deleteIndex) => {
    setUploadFiles(
      [...uploadFiles].filter((file, index) => deleteIndex !== index)
    );
    setValue(
      name,
      [...uploadFiles].filter((file, index) => deleteIndex !== index)
    );
  };

  const onDeleteFileUrl = (deleteIndex) => {
    let files = getValues("fileUrl");
    for (let i = 0; i < files.length; i++) {
      if (deleteIndex === files[i].id) {
        files[i].isActive = false;
      }
    }

    setFileUrls([...fileUrls].filter((file, index) => deleteIndex !== index));

    setValue("fileUrl", files);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(imageSrc);
  console.log(fileUrls);
  console.log(getValues("fileUrl"));
  return (
    <StyledDropZone
      htmlFor="dropfile"
      onDragOver={onDragHandler}
      onDragEnter={onDragHandler}
      onDragLeave={onDragHandler}
      onDrop={onDropFileHandler}
    >
      <input
        type="file"
        id="dropfile"
        hidden
        onChange={onChangeFileHandler}
        accept={onlyImage ? ".jpg, .jpeg, .png" : "*"}
      />
      {uploadFiles.length === 0 && <DropIcon />}
      <Grid container>
        {fileUrls.map((url, index) => {
          if (url.isActive) {
            return (
              //   <Grid key={`file_${index}`} item xs={6} sm={4} md={3} lg={2} container>
              <Chip
                key={`file_${index}`}
                label={`file_${url.id + 1}`}
                onClick={(event) => {
                  event.preventDefault();
                  setImageSrc(url.url);
                  setOpen(true);
                }}
                onDelete={(event) => {
                  event.preventDefault();
                  onDeleteFileUrl(url.id);
                }}
              />
              //   </Grid>
            );
          }
        })}
        {uploadFiles.map((file, index) => (
          // <Grid key={`file_${index}`} item xs={6} sm={4} md={3} lg={2} container>
          <Chip
            key={`file_${index}`}
            label={file.name}
            onClick={(event) => {
              event.preventDefault();
              setImageSrc(URL.createObjectURL(file));
              setOpen(true);
            }}
            onDelete={(event) => {
              event.preventDefault();
              onDeleteFile(index);
            }}
          />
          //  </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ padding: "24px 24px" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <ImagePreview src={imageSrc} alt="Preview" />
        </DialogContent>
      </Dialog>
    </StyledDropZone>
  );
};

const StyledIcon = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  top: "50%",
  left: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  userSelect: "none",
  pointerEvents: "none",
  ["& .dropIcon"]: {
    fontSize: 100,
    color: "rgba(0,0,0,.32)",
  },
}));

const DropIcon = () => {
  return (
    <StyledIcon>
      <i className="fas fa-cloud dropIcon"></i>
      <Typography color="rgba(0,0,0,.32)!important" gutterBottom>
        คลิก หรือ ลาก ไฟล์ที่ต้องการ
      </Typography>
    </StyledIcon>
  );
};

const StyledDropFile = styled("div")(({ theme }) => ({
  padding: "16.5px 14px",
  // width: "100%",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  border: "1px solid #c4c4c4",
}));

const DropSingleFile = ({
  title,
  name,
  setValue,
  getValues,
  maxFile,
  maxSize,
  onlyImage,
  defaultValue,
  fileUrl,
}) => {
  return (
    <StyledDropFile>
      <Typography color="rgba(0,0,0,.6)!important" gutterBottom>
        {title}
      </Typography>

      <DropZone
        name={name}
        setValue={setValue}
        getValues={getValues}
        maxFile={maxFile}
        maxSize={maxSize}
        onlyImage={onlyImage}
        defaultValue={defaultValue}
        fileUrl={fileUrl}
      />
    </StyledDropFile>
  );
};

export default DropSingleFile;
