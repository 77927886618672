import React from "react";
import { useEffect, useState } from "react";
import { styled, Typography } from "@mui/material";
import Form from "./components/Form";
import maintenanceService from "../../../../services/maintenance.service";

const Root = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
}));

const EditTechnicianPage = (props) => {
  const [technician, setTechnician] = useState(null);
  const [types, setTypes] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    maintenanceService.getTechniciansById(props.match.params.id).then((res) => {
      if (res.status === 200) {
        console.log(res.data.data);
        setTechnician(res.data.data);
        const types = res.data.data.types.split(",");
        const types2 = [];
        for (let i = 0; i < types.length; i++) {
          types2.push({ title: types[i] });
        }
        console.log(types2);
        setTypes(types2);
      }
    });
  }, []);
  const onSuccessHandler = () => {
    props.history.push("/admin/technicians");
  };

  return (
    <div className="page">
      <Root>
        <Typography
          fontSize={24}
          marginTop={2}
          marginBottom={2}
          fontWeight={500}
          align="center"
        >
          แก้ไขข้อมูลช่าง
        </Typography>
        {technician && types && (
          <Form
            onSuccessHandler={onSuccessHandler}
            technician={technician}
            types={types}
          />
        )}
      </Root>
    </div>
  );
};

export default EditTechnicianPage;
