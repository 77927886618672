import React, { useEffect, useState } from "react";
import TruckLocation from "./Icon/truck.png";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import SiteService from "../../../../../../../services/site.service";

const MarkerDriver = (props) => {
  const [isLocationDriver, setLocationDriver] = useState([{ Lat: 0, Long: 0 }]);
  const truckIcon = {
    url: TruckLocation,
    scaledSize: new window.google.maps.Size(50, 40), // Size of the icon
    origin: new window.google.maps.Point(0, 0), // Origin point
    anchor: new window.google.maps.Point(25, 50), // Anchor point
  };

  useEffect(() => {
    if (props.inforInCar != null) {
      const updateLocationDriver = setInterval(() => {
        SiteService.getLocationDriverById(props.inforInCar.idDriver)
          .then((res) => {
            if (res.data) {
              setLocationDriver([
                { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
              ]);
            }
          })
          .catch((error) => {
            setLocationDriver([{ Lat: 0, Long: 0 }]);
          });
      }, 1000);
      return () => clearInterval(updateLocationDriver);
    }
  }, [props.inforInCar]);

  return (
    <Marker
      position={{
        lat: parseFloat(isLocationDriver[0].Lat),
        lng: parseFloat(isLocationDriver[0].Long),
      }}
      icon={truckIcon}
    />
  );
};

export default MarkerDriver;
