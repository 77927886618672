import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, styled, Stack } from "@mui/material";
import Container from "@mui/material/Container";

import {
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Box,
  Paper,
} from "@mui/material";
import ButtonBlue from "../../shared/buttonBlue/index.js";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { useForm, Controller, set } from "react-hook-form";
import UnicornLoading from "../../assets/loading.gif";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider, useSnackbar } from "notistack";

import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DateAdapter from "@mui/lab/AdapterDayjs";

import { useState } from "react";
import CreateExcel from "../../shared/createExcel/index.js";
import CrossAreaCarBookingService from "../../../../services/crossAreaCarBooking.service.js";
import CardBooking from "./card/index";
import DriverBookingService from "../../../../services/driverBooking.service.js";
import { getAllDriver } from "../../../../actions/user.js";
import dayjs from "dayjs";
dayjs.locale("th");
const StyledRoot = styled("div")(({ theme }) => ({
  paddingInline: "8vw",
  [theme.breakpoints.down("sm")]: {
    paddingInline: "0",
    marginLeft: "0",
    width: "100%",
  },
  "& .divCoverReturn": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
  },
  "& .Grid": {
    width: "100%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },

  "& .tabBar": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .CreateButton": {
    height: "50px",
    width: "200px",
    display: "flex",
    color: "white",
    fontSize: "15px",
    borderRadius: "10px",
    margin: "3px",
    backgroundColor: "#436ADC",
    ["&:hover"]: {
      backgroundColor: "#436ADC",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  "& .IconImage": {
    weight: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .content": {
    marginTop: "15px",
  },
  "& .Title": {
    textAlign: "center",
    fontSize: "25px",
    // margin: "25px 0px",
  },
  "& .TextNoBooking": {
    textAlign: "center",
    fontSize: "25px",
  },
}));

const HeaderOfDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
}));

const HeaderOfTitle = styled("div")(({ theme }) => ({
  display: "flex",
  width: "75%",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const HeaderOfButton = styled("div")(({ theme }) => ({
  display: "flex",
  width: "25%",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const head_DriverBooking = [
  "Driver Booking ID",
  "Name",
  "Telephone",
  "Email",
  "Section",
  "Department",
  "Company",
  "Cost Center",
  "Cost Element",
  "Booking Type",
  "From",
  "To",
  "Start Date",
  "End Date",
  "Start Time",
  "End Time",
  "From (Return)",
  "To (Return)",
  "Start Date (Return)",
  "End Date (Return)",
  "Start Time (Return)",
  "End Time (Return)",
  "Journey Detail",
  "Note",
  "Driver Name",
  "Driver Telephone",
  "Management Driver Status",
];

const DriverBookingForManager = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { result: allDriver } = useSelector((state) => state.drivers);
  const [allDriverBooking, setallDriverBooking] = useState([]);
  const [driverBookingFormat, setDriverBookingFormat] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const [isStartDate, setStartDate] = useState(null);
  const [isEndDate, setEndDate] = useState(null);
  const [isMore, setMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionInPage, setActionInPage] = useState(false);
  useEffect(() => {
    DriverBookingService.getAllNewDriverBooking()
      .then((res) => {
        if (res.data) {
          setallDriverBooking(res.data);
          setDriverBookingFormatData(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setallDriverBooking([]);
        setDriverBookingFormatData([]);
        setLoading(false);
      });
    if (!allDriver) {
      dispatch(getAllDriver());
    }
  }, [actionInPage]);
  const handleActionInPage = () => {
    setActionInPage(!actionInPage);
  };

  const updateStatusApproved = (id, status) => {
    DriverBookingService.postUpdateStatusApproveDriverBooking({
      idDriverBooking: id,
      statusApproved: status,
    })
      .then((res) => {
        if (res.data) {
          setActionInPage(!actionInPage);
          enqueueSnackbar("Update Approved Status success", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Update Approved Status failed", {
          variant: "error",
        });
      });
  };

  const onClickSearch = async () => {
    // console.log(isStartDate.toLocaleDateString(), isEndDate.toLocaleDateString(),new Date());
    setLoading(true);
    const startDate =
      isStartDate === null
        ? null
        : isStartDate.$d.toLocaleString("af-ZA").slice(0, 10);
    const endDate =
      isEndDate === null
        ? null
        : isEndDate.$d.toLocaleString("af-ZA").slice(0, 10);

    DriverBookingService.getDriverBookingByFilterForManager({
      name: getValues("name"),
      from: getValues("from"),
      to: getValues("to"),
      status: getValues("status"),
      startdate: startDate,
      enddate: endDate,
    }).then((res) => {
      if (res.data) {
        setallDriverBooking(res.data.data.result);
        setDriverBookingFormatData(res.data.data.result);
        setLoading(false);
      }
    });
  };
  const onClickSearchMore = () => {
    setMore(!isMore);
  };

  const onClickCard = (booking) => {
    props.history.push("/manager/driver_booking/detail", {
      booking: booking,
    });
  };
  const setDriverBookingFormatData = (data) => {
    const format = data.map((item) => {
      return {
        idDriverBooking: item.idDriverBooking,
        name: `${item.firstname_TH} ${item.lastname_TH}`,
        telephoneMobile: item.telephoneMobile,
        email: item.email,
        section: item.sectionName,
        department: item.departmentName,
        company: item.companyName,
        costCenter: item.costCenterCharge,
        costElement: item.costElementCharge,
        twoWay: item.twoWay ? "ไป - กลับ" : "เที่ยวเดียว",
        namePlaceFrom: item.namePlaceFrom,
        namePlaceTo: item.namePlaceTo,
        startDate: dayjs(item.startDate).format("DD MMM YYYY"),
        endDate: dayjs(item.endDate).format("DD MMM YYYY"),
        startTime: item.startTime,
        endTime: item.endTime,
        namePlaceFromReturn: item.namePlaceFromReturn,
        namePlaceToReturn: item.namePlaceToReturn,
        startDateReturn: item.twoWay
          ? dayjs(item.startDateReturn).format("DD MMM YYYY")
          : null,
        endDateReturn: item.twoWay
          ? dayjs(item.endDateReturn).format("DD MMM YYYY")
          : null,
        startTimeReturn: item.startTimeReturn,
        endTimeReturn: item.endTimeReturn,
        detailJourney: item.detailJourney,
        note: item.note,
        nameDriver: item.nameDriver,
        phoneDriver: item.phoneDriver,
        statusManageCar:
          item.statusManageCar === "Success" ? "จัดคนขับแล้ว" : "รอดำเนินการ",
      };
    });
    setDriverBookingFormat(format);
  };

  return (
    <Container maxWidth="xl">
      <StyledRoot>
        <div className="page">
          <HeaderOfDiv>
            <HeaderOfTitle>
              <Typography fontSize={34}>รายการขอคนขับรถทั้งหมด</Typography>
            </HeaderOfTitle>
            <HeaderOfButton>
              <CreateExcel
                name="Driver_Booking_Requested"
                headerValues={head_DriverBooking}
                data={driverBookingFormat}
              />
            </HeaderOfButton>
          </HeaderOfDiv>
          <Grid
            container
            spacing={3}
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FFFFFF",
              padding: "0px 25px 25px 0px",
              marginLeft: "0",
              marginTop: "10px",
              marginBottom: "20px",
              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {
                // padding: "25px 30px 25px 15px",
              },
              [theme.breakpoints.down("md")]: {
                // padding: "25px 30px 25px 15px",
              },
            })}
          >
            {/* <Stack
              direction="row"
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              style={{ marginTop: "25px", marginLeft: "25px" }}
            > */}

            <Grid item md={4} xs={12}>
              <TextField
                id="search"
                type="search"
                label="ค้นหาชื่อผู้จอง"
                inputProps={{ ...register("name") }}
                // sx={(theme) => ({
                //   width: 455,
                //   [theme.breakpoints.down("sm")]: {
                //     width: 300,
                //   },
                //   [theme.breakpoints.down("md")]: {
                //     width: 300,
                //   },
                // })}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="from"
                type="from"
                label="ค้นหาสถานที่รับ"
                inputProps={{ ...register("from") }}
                // sx={(theme) => ({
                //   width: 455,
                //   [theme.breakpoints.down("sm")]: {
                //     width: 300,
                //   },
                //   [theme.breakpoints.down("md")]: {
                //     width: 300,
                //   },
                // })}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                id="to"
                type="to"
                label="ค้นหาสถานที่ส่ง"
                inputProps={{ ...register("to") }}
                // sx={(theme) => ({
                //   width: 455,
                //   [theme.breakpoints.down("sm")]: {
                //     width: 300,
                //   },
                //   [theme.breakpoints.down("md")]: {
                //     width: 300,
                //   },
                // })}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            {/* </Stack> */}

            {/* <Stack
              direction="row"
              spacing={{ xs: 1, sm: 2 }}
              useFlexGap
              flexWrap="wrap"
              style={{ marginTop: "20px", marginLeft: "25px" }}
            > */}

            <Grid item md={4} xs={12}>
              <TextField
                inputProps={{ ...register("status") }}
                // value={isStatus}
                // onChange={(e) => setStatus(e.target.value)}
                label="สถานะอนุมัติ"
                select
                // sx={(theme) => ({
                //   width: 455,
                //   [theme.breakpoints.down("sm")]: {
                //     width: 300,
                //   },
                //   [theme.breakpoints.down("md")]: {
                //     width: 300,
                //   },
                // })}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                defaultValue={"ทั้งหมด"}
              >
                <MenuItem value={"ทั้งหมด"}>ทั้งหมด</MenuItem>
                <MenuItem value={"อนุมัติ"}>อนุมัติ</MenuItem>
                <MenuItem value={"ไม่อนุมัติ"}>ไม่อนุมัติ</MenuItem>
                <MenuItem value={"รออนุมัติ"}>รออนุมัติ</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="startdate"
                  inputProps={{ ...register("startdate") }}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label="วันที่เริ่ม"
                      inputFormat="DD MMMM YYYY"
                      value={isStartDate}
                      onChange={(date) => setStartDate(date)}
                      componentsProps={{
                        actionBar: {
                          actions: ["clear", "cancel", "accept"],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // sx={(theme) => ({
                          //   width: 455,
                          //   [theme.breakpoints.down("sm")]: {
                          //     width: 300,
                          //   },
                          //   [theme.breakpoints.down("md")]: {
                          //     width: 300,
                          //   },
                          // })}
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="th">
                <Controller
                  name="enddate"
                  inputProps={{ ...register("enddate") }}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      minDate={isStartDate}
                      value={isEndDate}
                      onChange={(date) => setEndDate(date)}
                      label="วันที่สิ้นสุด"
                      inputFormat="DD MMMM YYYY"
                      componentsProps={{
                        actionBar: {
                          actions: ["clear", "cancel", "accept"],
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // sx={(theme) => ({
                          //   width: 455,
                          //   [theme.breakpoints.down("sm")]: {
                          //     width: 300,
                          //   },
                          //   [theme.breakpoints.down("md")]: {
                          //     width: 300,
                          //   },
                          // })}
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* </Stack> */}
            <Grid
              item
              lg={12}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ButtonBlue
                variant="contained"
                onClick={onClickSearch}
                style={{
                  borderRadius: "15px",
                  marginRight: "16px",
                }}
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </ButtonBlue>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {loading ? (
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                marginTop={1}
              >
                <Paper
                  sx={{
                    overflow: "hidden",
                    border: "1px solid #EEEEEE",
                    backgroundColor: "#FFFFFF",
                    marginBottom: "20px",
                    padding: "25px 30px 25px 30px",
                    borderRadius: "15px",
                    textAlign: "center", // จัดให้รูปอยู่กึ่งกลาง
                  }}
                >
                  <img
                    src={UnicornLoading}
                    alt="Loading..."
                    style={{ width: "300px" }}
                  />
                  {/* ปรับขนาดของภาพตามที่ต้องการ */}
                </Paper>
              </Grid>
            ) : (
              <>
                {allDriverBooking.length > 0 ? (
                  allDriverBooking.toReversed().map((booking, index) => {
                    return (
                      <CardBooking
                        key={booking.idDriverBooking}
                        booking={booking}
                        onClickCard={onClickCard}
                        updateStatusApproved={updateStatusApproved}
                        handleActionInPage={handleActionInPage}
                      />
                    );
                  })
                ) : (
                  <Grid item xs={12} lg={12} style={{ marginTop: "30px" }}>
                    <Typography className="TextNoBooking">
                      ไม่มีรายการ
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      </StyledRoot>
    </Container>
  );
};
export default DriverBookingForManager;
