import React, { useEffect, useState } from "react";
import {
  Avatar,
  Stack,
  styled,
  Typography,
  Grid,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@mui/material";
import Card from "../../../shared/Card";
import dayjs from "dayjs";
import "dayjs/locale/th";
import Container from "@mui/material/Container";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StatusRequest from "../../../shared/StatusRequest";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import utils from "../../../../../utils";
import LoadingButton from "@mui/lab/LoadingButton";
import meetingRoomService from "../../../../../services/meetingRoom.service";
import ConfirmApproveModal from "../Card/ConfirmApproveModal";
import ConfirmDisApproveModal from "../Card/ConfirmDisApproveModal";
import { SnackbarProvider, useSnackbar } from "notistack";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1280,
  "& .date": {
    color: "#3f51b5",
  },
  "& .location": {
    color: "#d32f2f",
  },
  "& .text": {
    fontSize: 18,
  },
  "& .icon": {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
  },
  "& .status__approved": {
    color: "#2e7d32",
  },
  "& .status__disapproved": {
    color: "#d32f2f",
  },
  "& .status__pending": {
    color: "#fbc02d",
  },
  "& .EditIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .CheckIcon": {
    marginRight: "5px",
    color: "green",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
  "& .ClockIcon": {
    marginRight: "5px",
    color: "green",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  // position: "absolute",
  // top: "13px",
  // left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const EditDetailButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    color: "black",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const DivButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const GridContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
}));

const Text = styled(Typography)({
  fontSize: "18px",
  fontWeight: 420,
  marginRight: "10px",
});

const HighlightText = styled(Typography)({
  fontSize: "16px",
});
const HighlightTextMain = styled(Typography)({
  fontSize: "20px",
  fontWeight: 550,
});

const TextWithIcon = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
  justifyContent: "center",
  "& .Icon": {
    marginRight: "5px",
    color: "#293A80",
  },
  "& .LocationIcon": {
    marginRight: "5px",
    color: theme.palette.error.main,
  },
  "& .CalendarIcon": {
    marginRight: "5px",
    color: theme.palette.primary.main,
  },
}));

const AdminMeetingRoomDescriptionPageForManager = (props) => {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openConfirmDisApproveModal, setOpenConfirmDisApproveModal] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    meetingRoomService
      .getMeetingRoomBookingById(props.match.params.requestedId)
      .then((res) => {
        if (res.status === 200) {
          setBooking(res.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loading]);

  const onClickGoBack = () => {
    props.history.goBack();
  };

  const changeStatus = (index, status) => {
    meetingRoomService
      .postApprovedlMeetingRoomBooking({
        idBooking: index,
        status: status,
      })
      .then((res) => {
        if (res.status === 200) {
          setBooking(res.data);
          enqueueSnackbar("Update Status Success", { variant: "success" });
          setLoading(!loading);
        } else {
          enqueueSnackbar("Update Status Failed", { variant: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Update Status Failed", { variant: "error" });
      });
  };

  const onClickEditDetail = () => {
    props.history.push(`/admin/Newmeetingroom/edit_requested/`, {
      booking: booking,
    });
  };
  return (
    <Container maxWidth="lg">
      <div className="page">
        <Root>
          {booking && (
            <>
              <Card style={{ paddingLeft: "32px", marginBottom: "20px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DivButton>
                      <BackButton disableRipple onClick={onClickGoBack}>
                        <ArrowBackIcon
                          className="Icon"
                          style={{ color: "#99A8B2" }}
                        />
                      </BackButton>
                      <Typography fontSize={25} fontWeight={600}>
                        ร้องขอสถานที่
                      </Typography>
                    </DivButton>
                    {/* <DivButton>
                      <EditDetailButton
                        // disableRipple
                        // component={Link}
                        // to={`/maintenances/request`}
                        onClick={onClickEditDetail}
                      >
                        <EditRoundedIcon className="Icon" color="inherit" />
                      </EditDetailButton>
                    </DivButton> */}
                  </Grid>
                  <Grid item xs={12}>
                    <HighlightTextMain>ผู้ร้องขอ</HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ชื่อ - สกุล:</Text>
                    <Avatar
                      src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                      style={{ marginRight: "10px" }}
                    />
                    <HighlightText>{booking.nameUser}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>เบอร์: </Text>
                    <HighlightText>
                      {booking.phoneUser === null
                        ? "ไม่ระบุ"
                        : booking.phoneUser}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>Email: </Text>
                    <HighlightText>
                      {booking.emailUser === null
                        ? "ไม่ระบุ"
                        : booking.emailUser}
                    </HighlightText>
                  </GridContent>
                  {/* <GridContent item xs={12} sm={6} md={4}>
                  <Text>ส่วน: </Text>
                  <HighlightText>
                    {booking.department === null
                      ? "ไม่ระบุ"
                      : booking.department}
                  </HighlightText>
                </GridContent>
                <GridContent item xs={12} sm={6} md={4}>
                  <Text>บริษัท: </Text>
                  <HighlightText>
                    {booking.company === null ? "ไม่ระบุ" : booking.company}
                  </HighlightText>
                </GridContent> */}
                  <Grid item xs={12}>
                    <HighlightTextMain>
                      รายละเอียดร้องขอสถานที่
                    </HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>สถานะ: </Text>
                    {booking.statusApprove === "Success" && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={1}
                        active={1}
                      />
                    )}
                    {booking.statusApprove === null && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={null}
                        step={null}
                        active={1}
                      />
                    )}
                    {booking.statusApprove === "Reject" && (
                      <StatusRequest
                        boxSize={30}
                        fontSize={18}
                        borderRadius={8}
                        status={0}
                        active={1}
                      />
                    )}
                    {booking.statusApprove === null ? (
                      <HighlightText style={{ color: "#fbc02d" }}>
                        รอดำเนินการ
                      </HighlightText>
                    ) : booking.statusApprove === "Success" ? (
                      <HighlightText style={{ color: "#2e7d32" }}>
                        อนุมัติแล้ว
                      </HighlightText>
                    ) : booking.statusApprove === "Reject" ? (
                      <HighlightText style={{ color: "#d32f2f" }}>
                        ไม่อนุมัติ
                      </HighlightText>
                    ) : null}
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <LocationOnRoundedIcon className="LocationIcon" />
                    <Text>สถานที่: </Text>
                    <HighlightText>{booking.nameMeetingRoom}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <PeopleAltRoundedIcon className="Icon" />
                    <Text>จำนวนคน: </Text>
                    <HighlightText>{booking.numOfPeople} คน</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <CalendarMonthRoundedIcon className="CalendarIcon" />
                    <Text>วันที่: </Text>
                    <HighlightText>
                      {new Date(booking.startDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}{" "}
                      -{" "}
                      {new Date(booking.endDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <AccessTimeRoundedIcon className="ClockIcon" />
                    <Text>เวลา: </Text>
                    <HighlightText>
                      {`${booking.timeStart} น.- ${booking.timeEnd} น.`}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ประเภท: </Text>
                    <HighlightText>
                      {booking.isBookAllDay ? "จองรายวัน" : "จองรายชั่วโมง"}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ค่าใช้จ่าย: </Text>
                    <HighlightText>
                      {booking.totalPrice === null ||
                      booking.totalPrice === undefined
                        ? "ไม่ระบุ"
                        : utils.numberWithCommas(booking.totalPrice)}{" "}
                      บาท
                    </HighlightText>
                  </GridContent>
                  <Grid item xs={12}>
                    <HighlightTextMain>รายละเอียดสถานที่</HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ชื่อสถานที่: </Text>
                    <HighlightText>{booking.nameMeetingRoom}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <LocationOnRoundedIcon className="LocationIcon" />
                    <Text>ที่อยู่: </Text>
                    <HighlightText>{booking.place}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <GroupsRoundedIcon className="Icon" />
                    <Text>บรรจุคน: </Text>
                    <HighlightText>{booking.numberOfPeople} คน</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={12} md={12}>
                    <Text>อุปกรณ์/สิ่งอำนวยความสะดวก: </Text>
                    <HighlightText>{booking.facilities}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={12} md={12}>
                    <Text>รายละเอียด: </Text>
                    <HighlightText>{booking.detail}</HighlightText>
                  </GridContent>
                  <Grid item xs={12}>
                    <HighlightTextMain>
                      รายละเอียดผู้จัดการสถานที่
                    </HighlightTextMain>
                    <Divider />
                  </Grid>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>ชื่อ - สกุล:</Text>
                    <Avatar
                      src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                      style={{ marginRight: "10px" }}
                    />
                    <HighlightText>{booking.nameManager}</HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>เบอร์: </Text>
                    <HighlightText>
                      {booking.phoneManager === null ||
                      booking.phoneManager === ""
                        ? "ไม่ระบุ"
                        : booking.phoneManager}
                    </HighlightText>
                  </GridContent>
                  <GridContent item xs={12} sm={6} md={4}>
                    <Text>Email: </Text>
                    <HighlightText>
                      {booking.emailManager === null ||
                      booking.emailManager === ""
                        ? "ไม่ระบุ"
                        : booking.emailManager}
                    </HighlightText>
                  </GridContent>
                </Grid>
              </Card>
              {booking.statusApprove === null && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px", // ช่องว่างระหว่างปุ่ม
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenConfirmDisApproveModal(true)}
                  >
                    ไม่อนุมัติ
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4BB543", // สีเขียว
                      "&:hover": {
                        backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                      },
                    }}
                    onClick={() => setOpenConfirmApproveModal(true)}
                  >
                    อนุมัติ
                  </Button>
                </Box>
              )}
              {openConfirmApproveModal && (
                <ConfirmApproveModal
                  open={openConfirmApproveModal}
                  onClose={() => {
                    setOpenConfirmApproveModal(false);
                  }}
                  onChangeStatusHandler={changeStatus}
                  index={booking.idNewMeetingRoomBooking}
                />
              )}
              {openConfirmDisApproveModal && (
                <ConfirmDisApproveModal
                  open={openConfirmDisApproveModal}
                  onClose={() => {
                    setOpenConfirmDisApproveModal(false);
                  }}
                  onChangeStatusHandler={changeStatus}
                  index={booking.idNewMeetingRoomBooking}
                />
              )}
            </>
          )}
        </Root>
      </div>
    </Container>
  );
};

export default AdminMeetingRoomDescriptionPageForManager;
