import React from "react";
import { styled, Paper } from "@mui/material";

const Root = styled(Paper)(({ theme }) => ({
  padding: "24px 48px",
  // minWidth: 1240,
  // borderRadius: 16,
  borderRadius: "10px",
  cursor: "pointer",
  transition:
    "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 0 10px 0 rgba(73, 73, 73, 0.4)",
  },
  // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  //   boxShadow:
  //     "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  boxSizing: "border-box",
  position: "relative",
  ["& .title"]: {
    fontSize: 14,
    color: "#9e9e9e",
  },
  ["& .button"]: {
    width: 40,
    height: 40,
    color: "#fff",
  },
  ["& .confirmButton"]: {
    marginRight: 8,
    backgroundColor: "#43a047",
    ["&:hover"]: {
      backgroundColor: "#43a047",
    },
  },
  ["& .rejectButton"]: {
    backgroundColor: "#e53935",
    ["&:hover"]: {
      backgroundColor: "#e53935",
    },
  },
}));

const Card = ({ children }) => {
  return <Root>{children}</Root>;
};

export default Card;
