import React, { useEffect, useState } from "react";
import TruckLocation from "../../../user/sampleshuttle/NewSample/CardDetailSample/showMap/Icon/truck.png";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import siteService from "../../../../../services/site.service";

const Direction = (props) => {
  return (
    <>
      {props.directions && (
        <DirectionsRenderer
          directions={props.directions}
          options={{
            suppressMarkers: true,
            polylineOptions: { strokeColor: "#00995c", strokeWeight: 5 },
          }}
        />
      )}
    </>
  );
};

export default Direction;
