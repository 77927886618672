import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Avatar,
  Stack,
  styled,
  Typography,
  Grid,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Select,
  Box,
  Container,
  MenuItem,
  Switch,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import Card from "../../../../../shared/Card";
import { SnackbarProvider, useSnackbar } from "notistack";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import meetingRoomService from "../../../../../../../services/meetingRoom.service";
import utils from "../../../../../../../utils";

//FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // For event interaction like clicks
// import "./FullCalendar.css"; // Import custom CSS for FullCalendar
// import "@fullcalendar/common/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

const Root = styled("div")(({ theme }) => ({
  margin: "0 auto",
  padding: 16,
  maxWidth: 1280,
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const colors = [
  "#FF6F61",
  "#FF8A65",
  "#FFB74D",
  "#FFAB40",
  "#FFC107",
  "#FFD54F",
  "#4FC3F7",
  "#26C6DA",
  "#29B6F6",
  "#00ACC1",
  "#00897B",
  "#9CCC65",
  "#66BB6A",
  "#81C784",
  "#AB47BC",
  "#8E24AA",
  "#7B1FA2",
  "#EC407A",
  "#F06292",
  "#F50057",
];

const generateTimeOptions = (timeStart = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = timeStart.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};

const AdminMeetingRoomEditDescriptionPage = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [meetingRooms, setMeetingRooms] = useState(null);
  const [bookingIsNotFinished, setBookingIsNotFinished] = useState([]);
  const [
    bookingIsNotFinishedFilterByidRoom,
    setBookingIsNotFinishedFilterByidRoom,
  ] = useState(null);
  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());
  const [isBookAllDay, setIsBookAllDay] = useState(
    props.location.state.booking.isBookAllDay
  );
  const [timeStart, setTimeStart] = useState(
    props.location.state.booking.timeStart
  );
  const [timeEnd, setTimeEnd] = useState(props.location.state.booking.timeEnd);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    meetingRoomService.getAlltNewMeetingRoom().then((res) => {
      setMeetingRooms(res.data);
    });
    meetingRoomService.getAllMeetingRoomBookingIsNotFinished().then((res) => {
      if (res.data.length > 0) {
        const events = [];
        let index = 0;
        for (const booking of res.data) {
          if (
            booking.idNewMeetingRoomBooking !==
            props.location.state.booking.idNewMeetingRoomBooking
          ) {
            if (booking.isBookAllDay) {
              events.push({
                idNewMeetingRoomBooking: booking.idNewMeetingRoomBooking,
                idNewMeetingRoom: booking.idMeetingRoom,
                title: `Booked by ${booking.nameUser}`,
                start: `${dayjs(booking.startDate).format("YYYY-MM-DD")} 00:00`,
                end: `${dayjs(booking.endDate).format("YYYY-MM-DD")} 23:59`,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                allDay: true,
              });
            } else {
              const start = dayjs(booking.startDate);
              const end = dayjs(booking.endDate);
              const differenceInDays = end.diff(start, "day", true) + 1;
              for (let i = 0; i < differenceInDays; i++) {
                events.push({
                  idNewMeetingRoomBooking: booking.idNewMeetingRoomBooking,
                  idNewMeetingRoom: booking.idMeetingRoom,
                  title: `Booked by ${booking.nameUser}`,
                  start: `${dayjs(booking.startDate)
                    .add(i, "day")
                    .format("YYYY-MM-DD")} ${booking.timeStart}`,
                  end: `${dayjs(booking.startDate)
                    .add(i, "day")
                    .format("YYYY-MM-DD")} ${booking.timeEnd}`,

                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  allDay: false,
                });
              }
            }
            index++;
          }
        }
        setBookingIsNotFinished(events);
      }
    });
    if (props.location.state) {
      const booking = props.location.state.booking;
      setValue("idMeetingRoom", booking.idMeetingRoom);
      setValue("nameUser", booking.nameUser);
      setValue("phoneUser", booking.phoneUser);
      setValue("emailUser", booking.emailUser);
      setValue("startDate", booking.startDate);
      setValue("endDate", booking.endDate);
      setValue("timeStart", booking.timeStart);
      setValue("timeEnd", booking.timeEnd);
      setValue("isBookAllDay", booking.isBookAllDay);
      setValue("numOfPeople", parseInt(booking.numOfPeople));
      setValue("totalPrice", parseInt(booking.totalPrice));
      setTotalPrice(parseInt(booking.totalPrice));
      setValue("idNewMeetingRoomBooking", booking.idNewMeetingRoomBooking);
    }
  }, []);

  useEffect(() => {
    if (watch("idMeetingRoom")) {
      handleMeetingRoomChange(watch("idMeetingRoom"));
    }
  }, [watch("idMeetingRoom"), bookingIsNotFinished]);

  const handleTimeStartChange = (time) => {
    const newEndTimeOptions = generateTimeOptions(time);
    setEndTimeOptions(newEndTimeOptions);
    setTimeEnd(newEndTimeOptions[0]);
    setValue("timeEnd", newEndTimeOptions[0]);
    handleChangeTotalPrice();
  };

  const handleMeetingRoomChange = (id) => {
    const meetingRoom = [];
    for (let i = 0; i < bookingIsNotFinished.length; i++) {
      if (bookingIsNotFinished[i].idNewMeetingRoom === id) {
        meetingRoom.push(bookingIsNotFinished[i]);
      }
    }
    setBookingIsNotFinishedFilterByidRoom(meetingRoom);
  };

  const onClickGoBack = () => {
    props.history.goBack();
  };

  function toMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  function isOverlapping(timeStart1, timeEnd1, timeStart2, timeEnd2) {
    const start1 = toMinutes(timeStart1);
    const end1 = toMinutes(timeEnd1);
    const start2 = toMinutes(timeStart2);
    const end2 = toMinutes(timeEnd2);
    return start1 < end2 && end1 > start2;
  }

  const handleChangeTotalPrice = () => {
    let price = 0;
    for (let i = 0; i < meetingRooms.length; i++) {
      if (meetingRooms[i].idNewMeetingRoom === getValues("idMeetingRoom")) {
        if (getValues("isBookAllDay") !== true) {
          price = meetingRooms[i].price;
          break;
        } else {
          price = meetingRooms[i].priceAllDay;
          break;
        }
      }
    }
    const checkStartDate = dayjs(getValues("startDate"));
    const checkEndDate = dayjs(getValues("endDate"));
    const diffInHours =
      (toMinutes(getValues("timeEnd")) - toMinutes(getValues("timeStart"))) /
      60;
    const diffInDay = checkEndDate.diff(checkStartDate, "day") + 1;
    if (getValues("isBookAllDay") !== true) {
      setTotalPrice(diffInDay * diffInHours * price);
      setValue("totalPrice", diffInDay * diffInHours * price);
    } else {
      setTotalPrice(diffInDay * price);
      setValue("totalPrice", diffInDay * price);
    }
  };

  const onSubmit = (data) => {
    let checkTime = false;
    const checkStartDate = dayjs(data.startDate);
    const checkEndDate = dayjs(data.endDate);
    for (let i = 0; i < meetingRooms.length; i++) {
      if (meetingRooms[i].idNewMeetingRoom === getValues("idMeetingRoom")) {
        if (
          parseInt(meetingRooms[i].numberOfPeople) <
          parseInt(getValues("numOfPeople"))
        ) {
          checkTime = true;
          enqueueSnackbar(
            `จํานวนผู้ใช้งานต้องไม่เกิน ${meetingRooms[i].numberOfPeople} คน`,
            {
              variant: "error",
            }
          );
        }
        break;
      }
    }
    if (checkEndDate.diff(checkStartDate, "day") < 0) {
      checkTime = true;
      enqueueSnackbar("วันที่สิ้นสุดต้องมากกว่าหรือเท่ากับวันที่เริ่มต้น", {
        variant: "error",
      });
    }
    if (checkTime === false) {
      for (let e = 0; e < bookingIsNotFinishedFilterByidRoom.length; e++) {
        const rangeStart = dayjs(
          bookingIsNotFinishedFilterByidRoom[e].start.split(" ")[0]
        );
        const rangeEnd = dayjs(
          bookingIsNotFinishedFilterByidRoom[e].end.split(" ")[0]
        );
        if (
          (checkStartDate >= rangeStart && checkStartDate < rangeEnd) ||
          (checkEndDate > rangeStart && checkEndDate <= rangeEnd) ||
          (checkStartDate <= rangeStart && checkEndDate >= rangeEnd)
        ) {
          if (
            bookingIsNotFinishedFilterByidRoom[e].allDay ||
            data.isBookAllDay
          ) {
            checkTime = true;
            break;
          } else {
            if (
              isOverlapping(
                data.timeStart,
                data.timeEnd,
                bookingIsNotFinishedFilterByidRoom[e].start.split(" ")[1],
                bookingIsNotFinishedFilterByidRoom[e].end.split(" ")[1]
              )
            ) {
              checkTime = true;
              break;
            } else {
              checkTime = false;
            }
          }
        }
      }

      if (checkTime === false) {
        const senddata = {
          idMeetingRoom: data.idMeetingRoom,
          nameUser: data.nameUser,
          phoneUser: data.phoneUser,
          emailUser: data.emailUser,
          startDate: data.startDate,
          endDate: data.endDate,
          timeStart: isBookAllDay ? "00:00" : data.timeStart,
          timeEnd: isBookAllDay ? "23:59" : data.timeEnd,
          isBookAllDay: data.isBookAllDay,
          numOfPeople: data.numOfPeople,
          totalPrice: data.totalPrice,
          idNewMeetingRoomBooking: data.idNewMeetingRoomBooking,
        };

        meetingRoomService
          .postUpdateMeetingRoomBooking(senddata)
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar("Update Booking Success", {
                variant: "success",
              });
              onClickGoBack();
            } else {
              enqueueSnackbar("Update Booking Failed", { variant: "error" });
            }
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar("Update Booking Failed", { variant: "error" });
          });
      } else {
        enqueueSnackbar("เวลาที่เลือกถูกจองไปแล้ว", { variant: "error" });
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <div className="page">
        <Root>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <BackButton disableRipple onClick={onClickGoBack}>
                  <ArrowBackIcon
                    className="Icon"
                    style={{ color: "#99A8B2" }}
                  />
                </BackButton>
                <Typography fontSize={24} fontWeight={500}>
                  แก้ไขการจองสถานที่
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography fontSize={18} fontWeight={300}>
                  รายละเอียดผู้แจ้ง
                </Typography>
                <Divider />
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginLeft: "0px",
                  padding: "0 25px 25px 0",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {},
                  [theme.breakpoints.down("md")]: {},
                })}
              >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    name="nameUser"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label="ชื่อผู้แจ้ง"
                        InputLabelProps={{ shrink: field.value ? true : false }}
                        {...field}
                        helperText={
                          errors.nameUser ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                        }
                        sx={{
                          ".MuiInputBase-root": {
                            borderRadius: "10px",
                          },
                        }}
                        error={errors.nameUser ? true : false}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    name="phoneUser"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label="เบอร์โทรศัพท์"
                        InputLabelProps={{ shrink: field.value ? true : false }}
                        {...field}
                        helperText={
                          errors.phoneUser ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                        }
                        sx={{
                          ".MuiInputBase-root": {
                            borderRadius: "10px",
                          },
                        }}
                        error={errors.phoneUser ? true : false}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    name="emailUser"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Email"
                        InputLabelProps={{ shrink: field.value ? true : false }}
                        {...field}
                        helperText={
                          errors.emailUser ? "กรุณากรอกข้อมูลให้ถูกต้อง" : false
                        }
                        sx={{
                          ".MuiInputBase-root": {
                            borderRadius: "10px",
                          },
                        }}
                        error={errors.emailUser ? true : false}
                        InputProps={{
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontSize={18} fontWeight={300}>
                    รายละเอียดสถานที่จอง
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={14}>จองรายชั่วโมง</Typography>
                    <Switch
                      checked={isBookAllDay}
                      onChange={() => {
                        setValue("isBookAllDay", !isBookAllDay);
                        setIsBookAllDay(!isBookAllDay);
                        handleChangeTotalPrice();
                      }}
                    />
                    <Typography fontSize={14}>จองรายวัน</Typography>
                  </div>
                </div>
                <Divider />
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginLeft: "0px",
                  padding: "0 25px 25px 0",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {},
                  [theme.breakpoints.down("md")]: {},
                })}
              >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  {meetingRooms && (
                    <Controller
                      name="idMeetingRoom"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          select
                          label="สถานที่"
                          fullWidth
                          error={errors.idMeetingRoom ? true : false}
                          helperText={
                            errors.idMeetingRoom ? "กรุณาเลือก" : false
                          }
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChangeTotalPrice();
                          }}
                          value={field.value}
                        >
                          {meetingRooms.map((item) => {
                            if (item.isActive)
                              return (
                                <MenuItem
                                  key={item.idNewMeetingRoom}
                                  value={item.idNewMeetingRoom}
                                >
                                  {item.nameMeetingRoom}
                                </MenuItem>
                              );
                          })}
                        </TextField>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="วันที่เริ่ม"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              error={errors.startDate ? true : false}
                              helperText={
                                errors.startDate ? "กรุณากรอก" : false
                              }
                              InputProps={{
                                style: {
                                  borderRadius: "10px",
                                },
                              }}
                              {...params}
                            />
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChangeTotalPrice();
                          }}
                          value={field.value}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="วันที่สิ้นสุด"
                          inputFormat="DD/MM/YYYY"
                          minDate={watch("startDate")}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              error={errors.endDate ? true : false}
                              helperText={errors.endDate ? "กรุณากรอก" : false}
                              InputProps={{
                                style: {
                                  borderRadius: "10px",
                                },
                              }}
                              {...params}
                            />
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChangeTotalPrice();
                          }}
                          value={field.value}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                {!isBookAllDay && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <FormControl fullWidth>
                            <InputLabel shrink>{"เวลาเริ่ม"}</InputLabel>
                            <Select
                              native
                              label="เวลาเริ่ม"
                              value={timeStart}
                              disableUnderline
                              onChange={(e) => {
                                propsInController.field.onChange(
                                  e.target.value
                                );
                                setTimeStart(e.target.value);
                                handleTimeStartChange(e.target.value);
                                //   handleChangeStart(e.target.value);
                              }}
                              style={{ borderRadius: "10px" }}
                            >
                              {generateTimeOptions().map((time, index) => {
                                if (time !== "23:30")
                                  return (
                                    <option key={index} value={time}>
                                      {time}
                                    </option>
                                  );
                              })}
                            </Select>
                          </FormControl>
                        )}
                        name="timeStart"
                        control={control}
                        rules={{ required: isBookAllDay ? false : true }}
                        helperText={
                          timeStart === "00:00"
                            ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                            : false
                        }
                        error={timeStart === "00:00" ? true : false}
                        defaultValue="00:00"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Controller
                        render={(propsInController) => (
                          <FormControl fullWidth>
                            <InputLabel shrink>{"เวลาสิ้นสุด"}</InputLabel>
                            <Select
                              native
                              label="เวลาสิ้นสุด"
                              value={timeEnd}
                              disableUnderline
                              onChange={(e) => {
                                propsInController.field.onChange(
                                  e.target.value
                                );
                                setTimeEnd(e.target.value);
                                handleChangeTotalPrice();
                                //   handleChangeEnd(e.target.value);
                              }}
                              style={{ borderRadius: "10px" }}
                            >
                              {endTimeOptions.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        name="timeEnd"
                        control={control}
                        rules={{ required: isBookAllDay ? false : true }}
                        error={errors.timeEnd ? true : false}
                        defaultValue="00:00"
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="จำนวนคน"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 1,
                          },
                          style: {
                            borderRadius: "10px",
                          },
                        }}
                        error={errors.numOfPeople ? true : false}
                        helperText={
                          errors.numOfPeople
                            ? "กรุณากรอกข้อมูลให้ถูกต้อง"
                            : false
                        }
                        {...field}
                      />
                    )}
                    name="numOfPeople"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={18}>ค่าบริการ</Typography>
                  <Typography fontSize={18} marginLeft={"10px"}>
                    {utils.numberWithCommas(totalPrice)} บาท
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Typography fontSize={18} fontWeight={300}>
                  ตารางการจอง
                </Typography>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow:
                    "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
                  border: "1px solid #EEEEEE",
                  backgroundColor: "#FBFCFC",
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginLeft: "0px",
                  padding: "0 25px 25px 0",

                  borderRadius: "15px",
                  [theme.breakpoints.down("sm")]: {},
                  [theme.breakpoints.down("md")]: {},
                })}
              >
                <div style={{ width: "60%", marginTop: "20px" }}>
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    events={bookingIsNotFinishedFilterByidRoom}
                    dayMaxEvents={true} // allow "more" link when too many events  true
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#4BB543", // สีเขียว
                    "&:hover": {
                      backgroundColor: "#43A047", // สีเขียวเข้มขึ้นเมื่อ hover
                    },
                  }}
                  type="submit"
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </form>
        </Root>
      </div>
    </Container>
  );
};

export default AdminMeetingRoomEditDescriptionPage;
