import React from "react";
import { styled, Grid } from "@mui/material";

const Root = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "120px",
  //   transition:
  //     "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  //   boxShadow:
  //     "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
  transition: "transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxShadow: "0 2px 4px 0 rgb(35 31 32 / 20%), 0 0 1px 0 rgb(35 31 32 / 10%)",
  position: "relative",

  display: "flex",
  // padding: "0 10px",
  borderRadius: "15px",
  cursor: "pointer",
  border: "1px solid #EEEEEE",
  backgroundColor: "#FBFCFC",
  // transition:
  //   "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow:
      "rgb(145 158 171 / 60%) 0px 0px 2px 0px, rgb(145 158 171 / 60%) 0px 20px 40px -4px",
  },
  [theme.breakpoints.down("lg")]: {
    height: "fit-content",
    flexDirection: "column",
    padding: "15px 15px 15px 15px",
  },
}));

const GridCard = ({ children, style }) => {
  return <Root style={style}>{children}</Root>;
};

export default GridCard;
