import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/styles";

import makeStyles from "@mui/styles/makeStyles";
import { Modal, Typography, Paper, IconButton } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CloseIcon from "@mui/icons-material/Close";

import GoogleMapReact from "google-map-react";
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  GoogleAPIkey,
  locationSite1,
} from "../../../../../../src/utils/googleMapAssets";
import MarkerDriver from "./MarkerDriver";
import SiteService from "../../../../../services/site.service";

const placesLibrary = ["places"];

const useStyles = makeStyles((theme) => ({
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Paper: {
    width: "800px",
    height: "600px",
    padding: "20px",
  },
  partOfTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  partOfTitleLeft: {
    display: "flex",
    alignItems: "center",
  },
  IconButtonClose: {
    color: theme.palette.primary.main,
  },
  IconCar: {
    fontSize: "40px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  Title: {
    fontSize: "30px",
    color: theme.palette.primary.main,
  },
}));

const AlertMessage = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  fontSize: "16px",
  color: "red",
});

const ModalShowmap = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, control, watch, getValues, setValue } =
    useForm();
  const [isLocationDriver, setLocationDriver] = useState([
    { Lat: props.isLocationDriver.Lat, Long: props.isLocationDriver.Long },
  ]);
  const [siteFrom, setSiteFrom] = useState(null);
  const [siteTo, setSiteTo] = useState(null);
  const [fromPlace, setFromPlace] = useState(null);
  const [toPlace, setToPlace] = useState(null);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleAPIkey,
    libraries: placesLibrary,
    language: "th",
    region: "TH",
  });

  useEffect(() => {
    if (props.inforInCar != null) {
      if (props.typeBooking !== "BetweenSiteCar") {
        SiteService.getLocationDriverById(props.inforInCar.idDriver)
          .then((res) => {
            if (res.data) {
              setLocationDriver([
                { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
              ]);
            }
          })
          .catch((error) => {
            setLocationDriver([{ Lat: 0, Long: 0 }]);
          });
      } else {
        SiteService.getLocationDriverById(props.inforInCar.idDriverRouteDay)
          .then((res) => {
            if (res.data) {
              setLocationDriver([
                { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
              ]);
            }
          })
          .catch((error) => {
            setLocationDriver([{ Lat: 0, Long: 0 }]);
          });
      }

      if (props.typeBooking === "DeliverySampleShuttleCar") {
        SiteService.getSitebyName(props.inforInCar.fromPlace).then((res) => {
          if (res.data) {
            setSiteFrom(res.data[0]);
          } else {
            setSiteFrom(null);
          }
        });
        SiteService.getSitebyName(props.inforInCar.toPlace).then((res) => {
          if (res.data) {
            setSiteTo(res.data[0]);
          } else {
            setSiteTo(null);
          }
        });
      } else if (props.typeBooking === "BetweenSiteCar") {
        SiteService.getSitebyName(props.inforInCar.gettingPlace).then((res) => {
          if (res.data) {
            setSiteFrom(res.data[0]);
          } else {
            setSiteFrom(null);
          }
        });
        SiteService.getSitebyName(props.inforInCar.toPlace).then((res) => {
          if (res.data) {
            setSiteTo(res.data[0]);
          } else {
            setSiteTo(null);
          }
        });
      } else {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { address: props.inforInCar.fromPlace },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
              setFromPlace(results[0].geometry.location); // ตำแหน่งที่ได้จาก Geocoder
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
            }
          }
        );
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: props.inforInCar.toPlace },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            setToPlace(results[0].geometry.location); // ตำแหน่งที่ได้จาก Geocoder
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  }, [props.inforInCar]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const defaultProps = {
    center: {
      lat: parseFloat(isLocationDriver[0].Lat),
      lng: parseFloat(isLocationDriver[0].Long),
      // lat: parseFloat(12.5),
      // lng: parseFloat(1.5),
    },
    zoom: 13,
  };

  return (
    <>
      {isLoaded && props.inforInCar && isLocationDriver && (
        <Modal
          className={`${classes.Modal}`}
          open={props.open}
          onClose={props.onCloseHandle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={`${classes.Paper}`}>
            <div className={`${classes.partOfTitle}`}>
              <div className={`${classes.partOfTitleLeft}`}>
                <DriveEtaIcon className={`${classes.IconCar}`} />
                <Typography className={`${classes.Title}`}>
                  แสดงแผนที่
                </Typography>
              </div>

              <IconButton
                className={`${classes.IconButtonClose}`}
                onClick={props.onCloseHandle}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
              >
                {props.typeBooking !== "BetweenSiteCar" ? (
                  <>
                    {props.inforInCar.idDriver ? (
                      <MarkerDriver
                        inforInCar={props.inforInCar}
                        isBetweenSiteCar={false}
                        siteFrom={siteFrom}
                        siteTo={siteTo}
                        isLocationDriver={isLocationDriver}
                      />
                    ) : (
                      <AlertMessage>ไม่พบข้อมูล</AlertMessage>
                    )}
                  </>
                ) : (
                  <>
                    {props.inforInCar.idDriverRouteDay ? (
                      <MarkerDriver
                        inforInCar={props.inforInCar}
                        isBetweenSiteCar={true}
                        siteFrom={siteFrom}
                        siteTo={siteTo}
                        isLocationDriver={isLocationDriver}
                      />
                    ) : (
                      <AlertMessage>ไม่พบข้อมูล</AlertMessage>
                    )}
                  </>
                )}
                {siteTo && (
                  <Marker
                    position={{
                      lat: parseFloat(siteTo.Lat),
                      lng: parseFloat(siteTo.Long),
                    }}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#d9534f" width="56px" height="56px">
                          <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                        </svg>
                      `),
                    }}
                  />
                )}
                {toPlace && (
                  <Marker
                    position={toPlace}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#d9534f" width="56px" height="56px">
                          <path d="M12 2C8.69 2 6 4.69 6 8c0 3.53 3.87 7.61 5.47 9.19.3.3.77.3 1.06 0C14.13 15.61 18 11.53 18 8c0-3.31-2.69-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                        </svg>
                      `),
                    }}
                  />
                )}
                {siteFrom && (
                  <Marker
                    position={{
                      lat: parseFloat(siteFrom.Lat),
                      lng: parseFloat(siteFrom.Long),
                    }}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
                        <svg height="48" viewBox="0 0 1792 1792" width="48" xmlns="http://www.w3.org/2000/svg" fill="#005f40">
            <path d="M1536 1536q0 63-61.5 113.5t-164 81-225 46-253.5 15.5-253.5-15.5-225-46-164-81-61.5-113.5q0-49 33-88.5t91-66.5 118-44.5 131-29.5q26-5 48 10.5t26 41.5q5 26-10.5 48t-41.5 26q-58 10-106 23.5t-76.5 25.5-48.5 23.5-27.5 19.5-8.5 12q3 11 27 26.5t73 33 114 32.5 160.5 25 201.5 10 201.5-10 160.5-25 114-33 73-33.5 27-27.5q-1-4-8.5-11t-27.5-19-48.5-23.5-76.5-25-106-23.5q-26-4-41.5-26t-10.5-48q4-26 26-41.5t48-10.5q71 12 131 29.5t118 44.5 91 66.5 33 88.5zm-384-896v384q0 26-19 45t-45 19h-64v384q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-384h-64q-26 0-45-19t-19-45v-384q0-53 37.5-90.5t90.5-37.5h384q53 0 90.5 37.5t37.5 90.5zm-96-384q0 93-65.5 158.5t-158.5 65.5-158.5-65.5-65.5-158.5 65.5-158.5 158.5-65.5 158.5 65.5 65.5 158.5z"/>
          </svg>
          
                      `),
                    }}
                  />
                )}
                {fromPlace && (
                  <Marker
                    position={fromPlace}
                    icon={{
                      url:
                        "data:image/svg+xml;charset=UTF-8," +
                        encodeURIComponent(`
        <svg height="48" viewBox="0 0 1792 1792" width="48" xmlns="http://www.w3.org/2000/svg" fill="#005f40">
          <path d="M1536 1536q0 63-61.5 113.5t-164 81-225 46-253.5 15.5-253.5-15.5-225-46-164-81-61.5-113.5q0-49 33-88.5t91-66.5 118-44.5 131-29.5q26-5 48 10.5t26 41.5q5 26-10.5 48t-41.5 26q-58 10-106 23.5t-76.5 25.5-48.5 23.5-27.5 19.5-8.5 12q3 11 27 26.5t73 33 114 32.5 160.5 25 201.5 10 201.5-10 160.5-25 114-33 73-33.5 27-27.5q-1-4-8.5-11t-27.5-19-48.5-23.5-76.5-25-106-23.5q-26-4-41.5-26t-10.5-48q4-26 26-41.5t48-10.5q71 12 131 29.5t118 44.5 91 66.5 33 88.5zm-384-896v384q0 26-19 45t-45 19h-64v384q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-384h-64q-26 0-45-19t-19-45v-384q0-53 37.5-90.5t90.5-37.5h384q53 0 90.5 37.5t37.5 90.5zm-96-384q0 93-65.5 158.5t-158.5 65.5-158.5-65.5-65.5-158.5 65.5-158.5 158.5-65.5 158.5 65.5 65.5 158.5z"/>
        </svg>
        `),
                    }}
                  />
                )}
              </GoogleMap>
            </div>
          </Paper>
        </Modal>
      )}
    </>
  );
};
export default ModalShowmap;
