import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/styles";
import {
  Paper,
  Button,
  Typography,
  Grid,
  Avatar,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";

import DetailCrossAreaCar from "./detailCrossAreaCar";
import DetailInAreaCar from "./detailInAreaCar";
import DetailDeliveryCar from "./detailDeliveryCar";
import DetailCrossAreaCarPool from "./detailCrossAreaCarPool";
import DetailDriverBooking from "./detailDriverBooking/index.js";
import DetailDeliverySampleShuttleCar from "./detailDeliverySampleShuttleCar/index.js";
import BetweenSiteCar from "./detailBetweenSiteCar/index.js";
import ModalShowmap from "./showMap/index.js";

import deliverySampleshuttleService from "../../../../services/deliverySampleshuttle.service.js";
import siteService from "../../../../services/site.service.js";

import { locationSite1 } from "../../../../utils/googleMapAssets.js";
import betweenSiteCarService from "../../../../services/betweenSiteCar.service.js";

const DivCoverReturn = styled("div")({
  height: "fit-content",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  width: "80%",
  maxWidth: "950px",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: "20px",
  marginBottom: "20px",
  boxShadow: theme.shadows[3],
  position: "relative",
  padding: "0 20px",
  "& .BackButton": {
    width: "fit-content",
    color: "black",
    position: "absolute",
    top: "13px",
    left: "0",
    padding: "0 25px",
    ["&:hover"]: {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      top: "9px",
      padding: "0 10px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "0 15px",
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  color: "black",
  position: "absolute",
  top: "13px",
  left: "10px",
  "&:hover": {
    backgroundColor: "inherit",
  },
  "& .Icon": {
    fontSize: "30px",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: "150px",
  height: "50px",
  color: "white",
  fontSize: "20px",
  [theme.breakpoints.down("sm")]: {
    width: "fit-Content",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
  marginTop: "10px",
}));

const GridContainer = styled(Grid)({
  width: "100%",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  height: "100%",
  width: "300px",
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: "1px",
  width: "100%",
  marginTop: "20px",
  marginBottom: "20px",
  backgroundColor: "#DDDDDD",
}));

const GridNavButton = styled(Grid)(({ theme }) => ({
  "& .DisapproveButton": {
    backgroundColor: "red",
    ["&:hover"]: {
      backgroundColor: "darkred",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .ApproveButton": {
    backgroundColor: "green",
    ["&:hover"]: {
      backgroundColor: "darkgreen",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "10px",
  },
}));

const GridButton = styled(Grid)({
  display: "flex",
  justifyContent: "center",
});

const Price = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "30px",
  margin: "10px 0",
}));
const ViewButton = styled(Button)({
  border: "1px solid #00bb77",
  color: "black",
  width: "100%",
  fontSize: "20px",
  // backgroundColor: "#3970C2",
  color: "#00bb77",
});

const GridPlace = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
}));

const StatusText = styled(Typography)(({ status }) => ({
  fontSize: "20px",
  textAlign: "left",
  color:
    status === "ได้รับสินค้าเรียบร้อย"
      ? "#4CAF50" // Green
      : status === "ส่งสินค้าเรียบร้อย"
      ? "#2196F3" // Blue
      : status === "รับสินค้าเรียบร้อย"
      ? "#FF9800" // Orange
      : status === "รอรับสินค้า"
      ? "#F44336" // Red
      : "inherit", // Default color
}));
const IsFinishText = styled(Typography)(({ status }) => ({
  fontSize: "20px",
  textAlign: "left",
  color: status ? "#4CAF50" : "#FF9800",
}));
const MainText = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
}));

const DetailBookingPageForDriver = (props) => {
  const { result: crossAreaBookings } = useSelector(
    (state) => state.crossAreaCar
  );
  const { result: crossAreaPoolBookings } = useSelector(
    (state) => state.crossAreaCarPool
  );
  const { result: inAreaBookings } = useSelector((state) => state.inAreaCar);
  const { result: deliveryBookings } = useSelector(
    (state) => state.deliveryCar
  );
  const { result: betweenSiteCar } = useSelector(
    (state) => state.betweenSiteCar
  );
  const { result: deliverySampleShuttleCar } = useSelector(
    (state) => state.deliverySampleShuttleCar
  );

  const { result: driverBookings } = useSelector(
    (state) => state.driverBooking
  );
  const { user: User } = useSelector((state) => state.auth);
  const [typeBooking, setTypeBooking] = useState(null);
  const [booking, setBooking] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const Width900 = useMediaQuery("(max-width:900px)");

  const [meetingRoom, setMeetingRoom] = useState(null);
  const [imageMeetingRoom, setImageMeetingRoom] = useState(null);
  const [isLocationDriver, setLocationDriver] = useState([
    { Lat: locationSite1.lat, Long: locationSite1.lng },
  ]);

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.typeBooking === "CrossArea") {
        setBooking(
          crossAreaBookings.find(
            (book) =>
              book.idCrossAreaCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "CrossAreaPool") {
        let booking = crossAreaPoolBookings.find(
          (book) =>
            book.idCrossAreaCarPoolBooking === props.location.state.idBooking
        );
        booking.idDriver = User.idUser;
        setBooking(booking);
      } else if (props.location.state.typeBooking === "InArea") {
        setBooking(
          inAreaBookings.find(
            (book) => book.idinAreaCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "Delivery") {
        setBooking(
          deliveryBookings.find(
            (book) =>
              book.idDeliveryCarBooking === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "BetweenSiteCar") {
        setBooking(
          betweenSiteCar.find(
            (book) => book.idBetweenSiteCar === props.location.state.idBooking
          )
        );
      } else if (
        props.location.state.typeBooking === "DeliverySampleShuttleCar"
      ) {
        setBooking(
          deliverySampleShuttleCar.find(
            (book) =>
              book.idDeliverySampleShuttle === props.location.state.idBooking
          )
        );
      } else if (props.location.state.typeBooking === "DriverBooking") {
        setBooking(
          driverBookings.find(
            (book) => book.idDriverBooking === props.location.state.idBooking
          )
        );
      }

      siteService
        .getLocationDriverById(User.idUser)
        .then((res) => {
          if (res.data) {
            setLocationDriver([
              { Lat: res.data[0].LatDriver, Long: res.data[0].LngDriver },
            ]);
          }
        })
        .catch((error) => {
          setLocationDriver([
            { Lat: locationSite1.lat, Long: locationSite1.lng },
          ]);
        });

      // setBooking(Bookings.find(book => book.id === props.location.state.idBooking));
      setTypeBooking(props.location.state.typeBooking);
    }
  }, []);

  const onCloseModalHandle = () => {
    setOpenModal(false);
  };

  const onClickOpenModal = () => {
    setOpenModal(true);
  };

  const onClickUpdate = async () => {
    const myData = {
      id: booking.idDeliverySampleShuttle,
      idDriver: User.idUser,
    };
    deliverySampleshuttleService
      .postUpdateDeliveryStatus(myData)
      .then((res) => {
        if (res.status === 200) {
          props.history.push(
            `/myListForDriver/delivery_sample_shuttle_car_booking`
          );
        }
      });
  };

  const onClickCancelCalling = () => {
    const myData = {
      id: booking.idBetweenSiteCar,
    };
    betweenSiteCarService
      .setFinishCallCar(myData)
      .then((res) => {
        if (res) {
          props.history.push(`/myListForDriver/between_site_car_booking`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TextUnderPicture = () => {
    if (typeBooking && booking) {
      if (typeBooking === "CrossArea") {
        return (
          <Typography style={{ textAlign: "center", fontSize: "20px" }}>
            {`${booking.vehicleTypes.vehicleTypeNameTH} ${booking.vehicleBrandsAndModels.model}`}
          </Typography>
        );
      } else if (typeBooking === "InArea") {
        return (
          <Typography style={{ textAlign: "center", fontSize: "20px" }}>
            {`${booking.vehicleTypes.vehicleTypeNameTH} GA ${
              booking.gaSite ? booking.gaSite : "ไม่ระบุ"
            }`}
          </Typography>
        );
      }
    }
  };

  const TextBooking = () => {
    if (typeBooking) {
      if (typeBooking === "CrossArea") {
        return <Text>การจองรถข้ามพื้นที่เฉพาะ</Text>;
      } else if (typeBooking === "CrossAreaPool") {
        return <Text>การจองรถข้ามพื้นที่ Pool</Text>;
      } else if (typeBooking === "InArea") {
        return <Text>การจองรถในพื้นที่</Text>;
      } else if (typeBooking === "BetweenSiteCar") {
        return <Text>การจองรถระหว่างสถานที่</Text>;
      } else if (typeBooking === "DriverBooking") {
        return <Text>การขอจองคนขับรถ</Text>;
      } else if (typeBooking === "Delivery") {
        return <Text>การจองรถรับส่งของ</Text>;
      } else if (typeBooking === "DeliverySampleShuttleCar") {
        return <Text>การจองรถรับส่งตัวอย่าง</Text>;
      }
    }
  };

  const TextFlight = () => {
    if (booking) {
      if (booking.flight === "oneWay") {
        return <Text>ประเภท : เที่ยวเดียว</Text>;
      } else if (booking.flight === "twoWay") {
        return <Text>ประเภท : ไป-กลับ</Text>;
      } else {
        return <Text>ประเภท : รอรับกลับ</Text>;
      }
    }
  };

  const onClickWatchTimeOfCrossAreaCarPool = () => {
    props.history.push("/myCrossAreaCarPool", {
      routeDate: booking.departureDate,
    });
  };

  const onClickGoBack = (istypeBooking) => {
    if (istypeBooking) {
      if (istypeBooking === "CrossArea") {
        props.history.push(`/myListForDriver/cross_area_car_booking`);
      } else if (istypeBooking === "CrossAreaPool") {
        props.history.push(`/myListForDriver/cross_area_car_pool_booking`);
      } else if (istypeBooking === "InArea") {
        props.history.push(`/myListForDriver/in_area_car_booking`);
      } else if (istypeBooking === "Delivery") {
        props.history.push(`/myListForDriver/delivery_car_booking`);
      } else if (istypeBooking === "BetweenSiteCar") {
        props.history.push(`/myListForDriver/between_site_car_booking`);
      } else if (istypeBooking === "DriverBooking") {
        props.history.push(`/myListForDriver/driver_booking`);
      } else if (istypeBooking === "DeliverySampleShuttleCar") {
        props.history.push(
          `/myListForDriver/delivery_sample_shuttle_car_booking`
        );
      }
    }
  };
  return (
    <DivCoverReturn className="page">
      {booking &&
        typeBooking != "DriverBooking" &&
        typeBooking != "DeliverySampleShuttleCar" &&
        typeBooking != "BetweenSiteCar" && (
          <PaperStyled>
            {/* <BackButton disableRipple onClick={props.history.goBack}>
            <ArrowBackIcon className="Icon" />
          </BackButton> */}
            <BackButton
              disableRipple
              onClick={() => {
                onClickGoBack(typeBooking);
              }}
            >
              <ArrowBackIcon className="Icon" />
            </BackButton>
            {/* <ButtonStyled
                        className="BackButton"
                        startIcon={<ArrowBackIcon />}
                        onClick={props.history.goBack}
                    >
                        Back
                    </ButtonStyled> */}
            <Title>การจองรถ</Title>
            <GridContainer container>
              <Grid container item xs={12} md={6}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AvatarStyled
                    style={{ height: "calc(100% - 30px)", width: "300px" }}
                    src={`${process.env.REACT_APP_URL}image/car/${
                      booking.vehicleTypes
                        ? booking.vehicleBrandsAndModels.imagepath
                        : "car6.jpeg"
                    }`}
                    alt={
                      booking.vehicleTypes
                        ? booking.vehicleBrandsAndModels.imagepath
                        : "car6.jpeg"
                    }
                    variant="square"
                  />
                  {typeBooking !== "Delivery" && TextUnderPicture()}
                </div>
              </Grid>
              {Width900 && (
                <Grid item xs={12}>
                  <DividerStyled />
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                md={6}
                alignContent="center"
                spacing={2}
                style={{ marginBottom: "20px" }}
              >
                <Grid item xs={12}>
                  {TextBooking()}
                </Grid>
                {typeBooking !== "Delivery" ? (
                  <>
                    <Grid item xs={12}>
                      <Text>{`จำนวนผู้โดยสาร : ${booking.numberOfPassenger} คน`}</Text>
                      {/* {booking.passengers.map((passengers) => 
                                        (<>
                                            <Text item xs={12}>{`ชื่อ : ${passengers.name}`}</Text>
                                            <Text item xs={12}>{`เบอร์ : ${passengers.telephoneMobile}`}</Text>
                                         </>
                                        ))} */}
                    </Grid>
                    {typeBooking === "CrossAreaPool" &&
                      booking.flight === "twoWay" && (
                        <Grid item xs={12}>
                          <Text>{`จำนวนผู้โดยสารขากลับ : ${booking.numberOfPassengerReturn} คน`}</Text>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      {TextFlight()}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Text>{`ประเภทของที่ส่ง : ${booking.typeProduct}`}</Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Text>{`น้ำหนัก : ${booking.weightProduct} kg`}</Text>
                    </Grid>
                  </>
                )}
                {typeBooking !== "CrossAreaPool" && (
                  <Grid item xs={12}>
                    <Text>{`วัตถุประสงค์ : ${
                      booking.purpose && booking.purpose !== ""
                        ? booking.purpose
                        : "ไม่ระบุ"
                    }`}</Text>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12} md={12} alignContent="center">
                <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
              </Grid>
            </GridContainer>
            <DividerStyled />
            <GridContainer container spacing={1}>
              <Grid item xs={12} md={6}>
                <Text>{`ผู้จอง : ${booking.name}`}</Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text>{`เบอร์โทรศัพท์ : ${booking.telephoneMobile}`}</Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text>{`email : ${booking.email}`}</Text>
              </Grid>
            </GridContainer>
            <DividerStyled />
            {typeBooking === "CrossArea" && (
              <DetailCrossAreaCar booking={booking} isManager={isManager} />
            )}
            {typeBooking === "CrossAreaPool" && (
              <DetailCrossAreaCarPool
                booking={booking}
                onClickWatchTime={onClickWatchTimeOfCrossAreaCarPool}
              />
            )}
            {typeBooking === "InArea" && (
              <DetailInAreaCar booking={booking} isManager={isManager} />
            )}
            {typeBooking === "Delivery" && (
              <DetailDeliveryCar booking={booking} />
            )}
            <DividerStyled />
            <Price>{`ค่าใช้จ่าย  :   ${
              booking.totalPrice ? booking.totalPrice : "ไม่ระบุ"
            }  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price>
            {isManager && (
              <>
                <DividerStyled />
                <GridNavButton container justifyContent="center" spacing={2}>
                  <GridButton item xs={12} sm={4}>
                    <ButtonStyled
                      className="ApproveButton"
                      startIcon={<CheckIcon />}
                    >
                      อนุมัติ
                    </ButtonStyled>
                  </GridButton>
                  <GridButton item xs={12} sm={4}>
                    <ButtonStyled
                      className="DisapproveButton"
                      startIcon={<CloseIcon />}
                    >
                      ไม่อนุมัติ
                    </ButtonStyled>
                  </GridButton>
                </GridNavButton>
              </>
            )}
          </PaperStyled>
        )}
      {booking && typeBooking === "DeliverySampleShuttleCar" && (
        <PaperStyled>
          <BackButton
            disableRipple
            onClick={() => {
              onClickGoBack(typeBooking);
            }}
          >
            <ArrowBackIcon className="Icon" />
          </BackButton>
          <Title>การจองรถ</Title>
          <GridContainer container>
            <Grid container item xs={12} md={6}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <AvatarStyled
                  style={{ height: "calc(100% - 30px)", width: "300px" }}
                  src={`${process.env.REACT_APP_URL}image/car/${"car6.jpeg"}`}
                  alt={"car6.jpeg"}
                  variant="square"
                />
              </div>
            </Grid>
            {Width900 && (
              <Grid item xs={12}>
                <DividerStyled />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              spacing={2}
              style={{ marginBottom: "20px" }}
            >
              <Grid item xs={12}>
                {TextBooking()}
              </Grid>

              <GridPlace item xs={12}>
                <Text>{`สถานะ : `}</Text>
                {booking.status === "ได้รับสินค้าเรียบร้อย" ? (
                  <CheckCircleRoundedIcon
                    style={{ color: "#4CAF50", marginRight: "5px" }}
                  />
                ) : (
                  <HourglassTopRoundedIcon
                    style={{
                      color:
                        booking.status === "ส่งสินค้าเรียบร้อย"
                          ? "#2196F3" // Blue
                          : booking.status === "รับสินค้าเรียบร้อย"
                          ? "#FF9800" // Orange
                          : booking.status === "รอรับสินค้า"
                          ? "#F44336" // Red
                          : "inherit",
                      marginRight: "5px",
                    }}
                  />
                )}
                <StatusText status={booking.status}>
                  {booking.status}
                </StatusText>
              </GridPlace>

              <Grid item xs={12}>
                <Text>{`ส่งของสำเร็จ : ${
                  booking.sendDate && booking.sendDate !== ""
                    ? new Date(booking.sendDate).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : " - "
                }`}</Text>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={onClickUpdate}
                  disabled={
                    booking.status === "ส่งสินค้าเรียบร้อย" ||
                    booking.status === "ได้รับสินค้าเรียบร้อย"
                      ? true
                      : false
                  }
                >
                  {booking.status === "รอรับสินค้า"
                    ? "กดรับสินค้า"
                    : booking.status === "รับสินค้าเรียบร้อย"
                    ? "กดส่งสินค้า"
                    : "ส่งสินค้าเรียบร้อย"}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} alignContent="center">
              <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดผู้ส่ง</MainText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้ส่ง : ${booking.nameSender}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ผู้ส่ง : ${booking.phoneSender}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.user.email}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <DetailDeliverySampleShuttleCar
            booking={booking}
            isManager={isManager}
          />
          {/* <DividerStyled /> */}
          {/* <Price>{`ค่าใช้จ่าย  :   6,400  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price> */}
          {isManager && (
            <>
              <DividerStyled />
              <GridNavButton container justifyContent="center" spacing={2}>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="ApproveButton"
                    startIcon={<CheckIcon />}
                  >
                    อนุมัติ
                  </ButtonStyled>
                </GridButton>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="DisapproveButton"
                    startIcon={<CloseIcon />}
                  >
                    ไม่อนุมัติ
                  </ButtonStyled>
                </GridButton>
              </GridNavButton>
            </>
          )}
        </PaperStyled>
      )}
      {booking && typeBooking === "BetweenSiteCar" && (
        <PaperStyled>
          <BackButton
            disableRipple
            onClick={() => {
              onClickGoBack(typeBooking);
            }}
          >
            <ArrowBackIcon className="Icon" />
          </BackButton>
          <Title>การจองรถ</Title>
          <GridContainer container>
            <Grid container item xs={12} md={6}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <AvatarStyled
                  style={{ height: "calc(100% - 30px)", width: "300px" }}
                  src={`${process.env.REACT_APP_URL}image/car/${"car6.jpeg"}`}
                  alt={"car6.jpeg"}
                  variant="square"
                />
              </div>
            </Grid>
            {Width900 && (
              <Grid item xs={12}>
                <DividerStyled />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              spacing={2}
              style={{ marginBottom: "20px" }}
            >
              <Grid item xs={12}>
                {TextBooking()}
              </Grid>

              <GridPlace item xs={12}>
                <Text>{`สถานะ : `}</Text>
                {booking.isFinish ? (
                  <CheckCircleRoundedIcon
                    style={{ color: "#4CAF50", marginRight: "5px" }}
                  />
                ) : (
                  <HourglassTopRoundedIcon
                    style={{
                      color: "#FF9800", // Orange
                      marginRight: "5px",
                    }}
                  />
                )}
                <IsFinishText status={booking.isFinish}>
                  {booking.isFinish ? "รายการเสร็จสิ้น" : "กําลังดําเนินการ"}
                </IsFinishText>
              </GridPlace>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={onClickCancelCalling}
                  disabled={booking.isFinish ? true : false}
                >
                  {booking.isFinish
                    ? "ส่งผู้โดยสารเรียบร้อย"
                    : "กดส่งผู้โดยสาร"}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} alignContent="center">
              <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={12}>
              <MainText>รายละเอียดผู้จอง</MainText>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้จอง : ${booking.user.firstname_TH} ${booking.user.lastname_TH}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ผู้จอง : ${booking.user.telephoneMobile}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.user.email}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <BetweenSiteCar booking={booking} isManager={isManager} />
          <DividerStyled />
          <Price>{`ค่าใช้จ่าย  :   6,400  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price>
          {isManager && (
            <>
              <DividerStyled />
              <GridNavButton container justifyContent="center" spacing={2}>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="ApproveButton"
                    startIcon={<CheckIcon />}
                  >
                    อนุมัติ
                  </ButtonStyled>
                </GridButton>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="DisapproveButton"
                    startIcon={<CloseIcon />}
                  >
                    ไม่อนุมัติ
                  </ButtonStyled>
                </GridButton>
              </GridNavButton>
            </>
          )}
        </PaperStyled>
      )}
      {booking && typeBooking === "DriverBooking" && (
        <PaperStyled>
          <BackButton
            disableRipple
            onClick={() => {
              onClickGoBack(typeBooking);
            }}
          >
            <ArrowBackIcon className="Icon" />
          </BackButton>
          <Title>การขอจองคนขับรถ</Title>
          <GridContainer container>
            <Grid container item xs={12} md={6}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <AvatarStyled
                  style={{ height: "200px", width: "200px", flexShrink: 0 }}
                  src={`${process.env.REACT_APP_URL}image/profile/${
                    booking.driver ? booking.driver.image : "1.jpg"
                  }`}
                  alt={booking.driver ? booking.driver.image : "1.jpg"}
                  variant="circle"
                />
              </div>
            </Grid>
            {Width900 && (
              <Grid item xs={12}>
                <DividerStyled />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              spacing={2}
            >
              <Grid item xs={12}>
                {TextBooking()}
              </Grid>

              <Grid item xs={12}>
                <Text>{`ชื่อคนขับรถ : ${
                  booking.nameDriver && booking.nameDriver !== ""
                    ? booking.nameDriver
                    : " - "
                }`}</Text>
              </Grid>

              <Grid item xs={12}>
                <Text>{`เบอร์โทร : ${
                  booking.phoneDriver && booking.phoneDriver !== ""
                    ? booking.phoneDriver
                    : " - "
                }`}</Text>
              </Grid>

              <Grid item xs={12}>
                <Text>{`ประเภท : ${
                  booking.twoWay == 0 ? "เที่ยวเดียว" : "ไป-กลับ"
                }`}</Text>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} alignContent="center">
              <ViewButton onClick={onClickOpenModal}>แสดงแผนที่</ViewButton>
            </Grid>
          </GridContainer>
          <DividerStyled />
          <GridContainer container spacing={1}>
            <Grid item xs={12} md={6}>
              <Text>{`ผู้จอง : ${booking.nameUser}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`เบอร์โทรศัพท์ : ${booking.user.telephoneMobile}`}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>{`email : ${booking.user.email}`}</Text>
            </Grid>
          </GridContainer>
          <DividerStyled />
          {typeBooking === "CrossArea" && (
            <DetailCrossAreaCar booking={booking} isManager={isManager} />
          )}
          {typeBooking === "CrossAreaPool" && (
            <DetailCrossAreaCarPool
              booking={booking}
              onClickWatchTime={onClickWatchTimeOfCrossAreaCarPool}
            />
          )}
          {typeBooking === "InArea" && (
            <DetailInAreaCar booking={booking} isManager={isManager} />
          )}
          {typeBooking === "Delivery" && (
            <DetailDeliveryCar booking={booking} />
          )}
          {typeBooking === "DriverBooking" && (
            <DetailDriverBooking booking={booking} />
          )}
          <DividerStyled />
          <Price>{`ค่าใช้จ่าย  :   6,400  THB   \(ไม่รวมค่าล่วงเวลา\)`}</Price>
          {isManager && (
            <>
              <DividerStyled />
              <GridNavButton container justifyContent="center" spacing={2}>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="ApproveButton"
                    startIcon={<CheckIcon />}
                  >
                    อนุมัติ
                  </ButtonStyled>
                </GridButton>
                <GridButton item xs={12} sm={4}>
                  <ButtonStyled
                    className="DisapproveButton"
                    startIcon={<CloseIcon />}
                  >
                    ไม่อนุมัติ
                  </ButtonStyled>
                </GridButton>
              </GridNavButton>
            </>
          )}
        </PaperStyled>
      )}

      <ModalShowmap
        open={openModal}
        onCloseHandle={onCloseModalHandle}
        typeBooking={typeBooking}
        inforInCar={booking}
        isLocationDriver={isLocationDriver}
      />
    </DivCoverReturn>
  );
};

export default DetailBookingPageForDriver;
