import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/styles";
import {
  Card,
  Grid,
  Avatar,
  Typography,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  TablePagination,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
require("dayjs/locale/th");
dayjs.locale("th");

const useStyles = makeStyles((theme) => ({
  DivCoverReturn: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Grid: {
    width: "80%",
    height: "100%",
    margin: "0 auto",
    marginTop: "20px",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
    },
  },
  Title: {
    textAlign: "center",
    fontSize: "25px",
    margin: "25px 0px",
  },
  TableRow: {
    ["&:hover"]: {
      cursor: "pointer",
    },
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  marginBottom: "20px",
  display: "flex",
  alignContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  paddingBottom: "10px",
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
  margin: "5px",
}));

const NameAndType = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const TextAndIcon = styled("div")({
  display: "flex",
  marginBottom: "5px",
  "& .Icon": {
    marginRight: "10px",
    color: "#293A80",
  },
  "& .CrossArea": {
    color: "#C400C6 !important",
  },
  "& .CrossAreaPool": {
    color: "#FF6600 !important",
  },
  "& .InArea": {
    color: "#0A81AB !important",
  },
  "& .Delivery": {
    color: "#8B4513 !important",
  },
});

const Text = styled(Typography)({
  color: "#293A80",
});

const DateAndTime = styled("div")({
  "@media (min-width: 700px) and (max-width: 780px)": {
    display: "flex",
  },
});

const DivStatus = styled("div")(({ theme }) => ({
  width: "150px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "5px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  "& .TextApproved": {
    color: "#6DCC6D",
  },
  "& .TextRejected": {
    color: "#EA4647",
  },
  "& .TextWaiting": {
    color: "#C3B816",
  },
  "& .TextApprovedForDriverBooking": {
    color: "#E30058",
    marginLeft: "5px",
  },
}));

const DivAvatarAndName = styled("div")(({ theme }) => ({
  display: "flex",

  alignItems: "center",
}));

const TextFirstnameAndLastName = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    paddingTop: "10px",
  },
  "& .FirstName": {
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
}));

const Name = styled(Typography)({
  color: "#185ADB",
  fontSize: "20px",
  marginBottom: "10px",
});

const IconStatus = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
  [theme.breakpoints.down("sm")]: {
    marginRight: "20px",
  },
}));

const TextStatus = styled(Typography)({
  fontSize: "15px",
  fontWeight: "bold",
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});
const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 14px",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const CrossAreaPoolCar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: AuthUser } = useSelector((state) => state.auth);
  const mobile = useMediaQuery("(max-width:600px)");

  const [showHistory, setHistory] = useState(0);
  const [listBookingNotSuccess, setListBookingNotSuccess] = useState([]);
  const [listBookingIsSuccess, setListBookingIsSuccess] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [props.showHistory]);

  useEffect(() => {
    let listBooking = props.booking;
    if (listBooking != null) {
      const listA = [];
      const listB = [];
      listBooking.map((booking) => {
        if (
          booking.statusApproved === "Success" &&
          booking.Approved === "Success" &&
          booking.statusManageCar === "Success"
        ) {
          listA.push(booking);
        } else {
          listB.push(booking);
        }
      });

      setListBookingIsSuccess(listA);
      setListBookingNotSuccess(listB);
    }
  }, []);

  const verifyTypeOfStatus = (statusApproved, Approved, statusManageCar) => {
    // console.log(statusApproved, Approved, statusManageCar);
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {verifyTpyeOfStatusIsApproved(
          statusApproved,
          Approved,
          statusManageCar
        ) ? (
          <CheckCircleRoundedIcon style={{ color: "#6DCC6D" }} />
        ) : verifyTpyeOfStatusIsRejected(statusApproved, Approved) ? (
          <CancelRoundedIcon style={{ color: "#EA4647" }} />
        ) : (
          <HourglassTopRoundedIcon style={{ color: "orange" }} />
        )}

        <TextStatus
          className={clsx({
            TextApproved: verifyTpyeOfStatusIsApproved(
              statusApproved,
              Approved,
              statusManageCar
            ),
            TextRejected: verifyTpyeOfStatusIsRejected(
              statusApproved,
              Approved
            ),
            TextWaiting:
              !verifyTpyeOfStatusIsApproved(
                statusApproved,
                Approved,
                statusManageCar
              ) && !verifyTpyeOfStatusIsRejected(statusApproved, Approved),
          })}
        >
          {verifyTpyeOfStatusIsApproved(
            statusApproved,
            Approved,
            statusManageCar
          ) ? (
            <Text style={{ color: "#6DCC6D" }}>อนุมัติแล้ว</Text>
          ) : verifyTpyeOfStatusIsRejected(statusApproved, Approved) ? (
            <Text style={{ color: "#EA4647" }}>ไม่อนุมัติ</Text>
          ) : (
            <Text style={{ color: "darkorange" }}>รออนุมัติ</Text>
          )}
        </TextStatus>
      </div>
    );
  };

  const verifyTpyeOfStatusIsApproved = (
    statusApproved,
    approved,
    statusManageCar
  ) => {
    if (
      statusApproved === "Success" &&
      approved === "Success" &&
      statusManageCar === "Success"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const verifyTpyeOfStatusIsRejected = (statusApproved, approved) => {
    if (statusApproved === "Success" && approved === false) {
      return true;
    } else {
      return false;
    }
  };

  const TextTypeBooking = () => {
    return (
      <>
        <EmojiTransportationIcon className="CrossAreaPool Icon" />
        <Typography className={"CrossAreaPool Icon"}>
          การจองรถข้ามพื้นที่ Pool
        </Typography>
      </>
    );
  };

  return (
    <Paper
      sx={{
        // width: "100%",
        overflow: "hidden",
        border: "1px solid #EEEEEE",
        backgroundColor: "#FFFFFF",

        marginBottom: "20px",
        // padding: "0px 25px 25px 0px",
        padding: "25px 30px 25px 30px",

        borderRadius: "15px",
      }}
    >
      {props.showHistory === 0 &&
      listBookingNotSuccess &&
      listBookingNotSuccess.length > 0 ? (
        <>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledCellHeader style={{ minWidth: "200px" }}>
                    ชื่อ
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "260px" }}
                  >
                    ประเภท
                  </StyledCellHeader>

                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "200px" }}
                  >
                    วันที่จอง
                  </StyledCellHeader>
                  {/* <StyledCellHeader>เวลา</StyledCellHeader> */}

                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "150px" }}
                  >
                    สถานะ
                  </StyledCellHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {listBookingNotSuccess
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((booking, index) => (
                    <TableRow
                      hover
                      key={index}
                      className={`${classes.TableRow}`}
                      onClick={() => {
                        props.onClickCardHandler(
                          props.typeOfBooking,
                          booking.idCrossAreaCarPoolBooking
                        );
                      }}
                    >
                      <StyledCellContent>
                        <DivAvatarAndName>
                          <AvatarStyled
                            src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                            alt={booking.name}
                          />
                          <NameAndType>
                            <TextFirstnameAndLastName>
                              {booking.name}
                            </TextFirstnameAndLastName>
                          </NameAndType>{" "}
                        </DivAvatarAndName>
                      </StyledCellContent>
                      <StyledCellContent>
                        <TextAndIcon>{TextTypeBooking()}</TextAndIcon>
                      </StyledCellContent>

                      <StyledCellContent>
                        <TextAndIcon>
                          <CalendarTodayIcon className="Icon" />
                          <Text>
                            {dayjs(booking.departureDate).format(
                              "DD MMMM YYYY"
                            )}
                          </Text>
                        </TextAndIcon>
                      </StyledCellContent>
                      {/* <StyledCellContent>
                      <TextAndIcon>
                        <AccessTimeRoundedIcon className="Icon" />
                        <Text>
                          {`${dayjs(booking.startTime).format(
                            "HH:mm"
                          )} - ${dayjs(booking.endTime).format("HH:mm")} น.`}
                        </Text>
                      </TextAndIcon>
                    </StyledCellContent> */}
                      <StyledCellContent>
                        {verifyTypeOfStatus(
                          booking.statusApproved,
                          booking.Approved,
                          booking.statusManageCar
                        )}
                      </StyledCellContent>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listBookingNotSuccess.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          {props.showHistory === 0 && (
            <Typography style={{ textAlign: "center", fontSize: "24px" }}>
              ไม่มีรายการ
            </Typography>
          )}
        </>
      )}
      {props.showHistory === 1 &&
      listBookingIsSuccess &&
      listBookingIsSuccess.length > 0 ? (
        <>
          <TableContainer style={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledCellHeader style={{ minWidth: "200px" }}>
                    ชื่อ
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "260px" }}
                  >
                    ประเภท
                  </StyledCellHeader>

                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "200px" }}
                  >
                    วันที่จอง
                  </StyledCellHeader>
                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "160px" }}
                  >
                    เวลา
                  </StyledCellHeader>

                  <StyledCellHeader
                    align="center"
                    style={{ minWidth: "150px" }}
                  >
                    สถานะ
                  </StyledCellHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {listBookingIsSuccess
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((booking, index) => (
                    <TableRow
                      hover
                      key={index}
                      className={`${classes.TableRow}`}
                      onClick={() => {
                        props.onClickCardHandler(
                          props.typeOfBooking,
                          booking.idCrossAreaCarPoolBooking
                        );
                      }}
                    >
                      <StyledCellContent>
                        <DivAvatarAndName>
                          <AvatarStyled
                            src={`${process.env.REACT_APP_URL}image/profile/1.jpg`}
                            alt={booking.name}
                          />
                          <NameAndType>
                            <TextFirstnameAndLastName>
                              {booking.name}
                            </TextFirstnameAndLastName>
                          </NameAndType>{" "}
                        </DivAvatarAndName>
                      </StyledCellContent>
                      <StyledCellContent>
                        <TextAndIcon>{TextTypeBooking()}</TextAndIcon>
                      </StyledCellContent>

                      <StyledCellContent>
                        <TextAndIcon>
                          <CalendarTodayIcon className="Icon" />
                          <Text>
                            {dayjs(booking.departureDate).format(
                              "DD MMMM YYYY"
                            )}
                          </Text>
                        </TextAndIcon>
                      </StyledCellContent>
                      <StyledCellContent>
                        <TextAndIcon>
                          <AccessTimeRoundedIcon className="Icon" />
                          <Text>
                            {`${dayjs(booking.startTime).format(
                              "HH:mm"
                            )} - ${dayjs(booking.endTime).format("HH:mm")} น.`}
                          </Text>
                        </TextAndIcon>
                      </StyledCellContent>
                      <StyledCellContent>
                        {verifyTypeOfStatus(
                          booking.statusApproved,
                          booking.Approved,
                          booking.statusManageCar
                        )}
                      </StyledCellContent>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listBookingIsSuccess.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <>
          {props.showHistory === 1 && (
            <Typography style={{ textAlign: "center", fontSize: "24px" }}>
              ไม่มีรายการ
            </Typography>
          )}
        </>
      )}
    </Paper>
  );
};

export default CrossAreaPoolCar;
